const LocalStorageService = (
    function () {
        const _setAccessToken = (accessToken) => {
            localStorage.setItem("access_token_story", accessToken);
        }
        const _getAccessToken = () => {
            return localStorage.getItem("access_token_story");
        }
        const _clearAccessToken = () => {
            localStorage.removeItem("access_token_story");
        }
        return {
            setAccessToken: _setAccessToken,
            getAccessToken: _getAccessToken,
            clearAccessToken: _clearAccessToken,
        };
    })();
export default LocalStorageService;