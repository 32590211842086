import React from "react";
import { Container, Typography } from "@mui/material";
import PackvideoCard from './Components/PackvideoCard/PackvideoCard'; // Assurez-vous que ce chemin est correct
import Pagination from "@mui/material/Pagination";
import styles from "./Packvideos.module.css"; // Assurez-vous que ce chemin est correct

export default function Packvideos(props) {
  // Affichez les props pour déboguer
  console.log("Packvideos Props:", props.packs);

  // Si aucune pack n'est trouvé, afficher un message approprié
  if (!props.packs || props.packs.length === 0) {
    return (
      <Container>
        <Typography>No packs found</Typography>
      </Container>
    );
  }

  // Calculer les packs à afficher en fonction de la page actuelle
  const packsToShow = props.packs.slice(
    (props.currentPage - 1) * 6, 
    props.currentPage * 6
  );

  return (
    <Container>
      <div className={styles["subsCards"]}>
        {packsToShow.length > 0 ? (
          packsToShow.map((packvideo) => <PackvideoCard key={packvideo.id} packvideo={packvideo} />) // Assurez-vous que `PackvideoCard` utilise `pack` comme prop
        ) : (
          <Typography>No packs found</Typography>
        )}
      </div>
      {props.packs.length > 0 && (
        <div className={styles["paginationContainer"]}>
          <Pagination
            count={Math.ceil(props.packs.length / 6)} // Nombre total de pages
            page={props.currentPage}
            onChange={props.handlePageChange}
            style={{ marginTop: "3%" }}
          />
        </div>
      )}
    </Container>
  );
}
