// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-bar{
    padding: 8px;
}
.accordion-button:not(.collapsed) {
    color: #66534d;
    background-color: #f8d7dd;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
label{
    color: #66534d;
}
.accordion-button {
    color: #66534d;
}
.accordion-body{
    padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/PackvideoPage/Packvideo/Sidebar/FilterBar/FilterBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,kGAAkG;AACtG;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".filter-bar{\n    padding: 8px;\n}\n.accordion-button:not(.collapsed) {\n    color: #66534d;\n    background-color: #f8d7dd;\n    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);\n}\nlabel{\n    color: #66534d;\n}\n.accordion-button {\n    color: #66534d;\n}\n.accordion-body{\n    padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
