// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/images/bg-angelique-hitmance-story.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.IntervenantsPage_intervenantsPage__-0xjm {
  display: flex;
  justify-content: center;
  margin: 5% 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: bottom;
}
body {
  background-color: #fff;
}


    
    `, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/IntervenantsPage.module.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,yDAA0E;EAC1E,4BAA4B;EAC5B,2BAA2B;AAC7B;AACA;EACE,sBAAsB;AACxB","sourcesContent":["/* styles.css */\n.intervenantsPage {\n  display: flex;\n  justify-content: center;\n  margin: 5% 0;\n  background-image: url(../../Assets/images/bg-angelique-hitmance-story.png);\n  background-repeat: no-repeat;\n  background-position: bottom;\n}\nbody {\n  background-color: #fff;\n}\n\n\n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intervenantsPage": `IntervenantsPage_intervenantsPage__-0xjm`
};
export default ___CSS_LOADER_EXPORT___;
