import React from "react";
import FilterBar from "./FilterBar/FilterBar";
import SearchBar from "./SearchBar/SearchBar";
import styles from "./Sidebar.module.css";

function Sidebar(props) {
  return (
    <div className={styles["sidebar"]}>
      <SearchBar
        subs={props.subs}
        setSearchTerm={props.setSearchTerm}
        setSliderPriceValues={props.setSliderPriceValues}
      />
      <FilterBar
        subs={props.subs}
        onFilter={props.onFilter}
        sliderPriceValues={props.sliderPriceValues}
        setSliderPriceValues={props.setSliderPriceValues}
        subsCount={props.subsCount}
      />
    </div>
  );
}
export default Sidebar;
