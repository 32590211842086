import React from "react";
import { Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import classes from "./PackvideoPage.module.css";
import Packvideos from "./Packvideo/Packvideos"; // Remplacez par le nom approprié
import Sidebar from "./Packvideo/Sidebar/Sidebar"; // Remplacez par le nom approprié
import GlobalContext from "../../../../Context/GlobalContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PackvideoPage() {
  const context = React.useContext(GlobalContext);
  console.log("Context Video Packs:", context.videoPacks); // Vérifiez si les données sont disponibles

  const [currentPage, setCurrentPage] = React.useState(1);
  const [filteredPacks, setFilteredPacks] = React.useState(context.videoPacks || []); // Assurez-vous d'utiliser `packs` au lieu de `subs`
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sliderPriceValues, setSliderPriceValues] = React.useState([10, 150]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    console.log("Context Video Packs:", context.videoPacks); // Vérifiez les données dans useEffect
    let updatedPacks = Array.isArray(context.videoPacks) ? context.videoPacks : [];
    // Filter by name
    if (searchTerm) {
      updatedPacks = updatedPacks.filter((videoPacks) =>
        videoPacks.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    // Filter by price
    updatedPacks = updatedPacks.filter(
      (pack) =>
        pack.price >= sliderPriceValues[0] && pack.price <= sliderPriceValues[1]
    );
  
    setFilteredPacks(updatedPacks);
  }, [context.videoPacks, searchTerm, sliderPriceValues]); // Ajoutez `context.videoPacks` aux dépendances
  

  return (
    <Grid container className={classes.PacksPage}> {/* Assurez-vous que ce nom correspond à votre fichier CSS */}
      <Grid item xs={12} md={2}>
        <Sidebar
          packs={context.videoPacks}
          sliderPriceValues={sliderPriceValues}
          setSliderPriceValues={setSliderPriceValues}
          packsCount={filteredPacks.length}
          setSearchTerm={setSearchTerm}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Packvideos
          packs={filteredPacks}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </Grid>
  );
}
