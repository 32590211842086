import React, { useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Button, Container, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import visioConf from "../../../../../Assets/images/visio-conferance-home-story.png";
import chat from "../../../../../Assets/images/chat-home-story.png";
import appelTel from "../../../../../Assets/images/appel-téléphonique-home-story.png";
import emailing from "../../../../../Assets/images/mailing-home-story.png";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Offres.module.css";
import { Typography } from "@mui/material";
import InfosModal from "./InfosModel/InfosModal";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const typeOffreImages = {
  chat: chat,
  call: appelTel,
  visio_conference: visioConf,
  emailing: emailing,
};

function Offres({ userData, Categories }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAllCategory, setSelectedAllCategory] = useState(true);
  const [selectedOffre, setSelectedOffre] = useState(null);
  const [openInfosModal, setOpenInfosModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(
    "Sélectionnez une catégorie pour voir les offres correspondantes"
  );

  const handleClickOpenInfosModal = (offre) => {
    setSelectedOffre(offre);
    setOpenInfosModal(true);
  };

  const offres = userData.info_offres;

  if (offres.length === 0) {
    return (
      <div className={styles["no-offres-message-container"]}>
        <Alert className={styles["no-offres-message"]} severity="info">
          No offres available.
        </Alert>
      </div>
    );
  }

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory((prevSelectedCategory) => {
      const newSelectedCategory =
        prevSelectedCategory === categoryId ? null : categoryId;

      setSelectedAllCategory(newSelectedCategory === null);
      newSelectedCategory === null
        ? setTooltipMessage(
            "Sélectionnez une catégorie pour voir les offres correspondantes"
          )
        : setTooltipMessage(
            "Cliquez pour voir toutes les offres sans filtre par catégorie"
          );

      return newSelectedCategory;
    });
  };

  const handleAllCategoryClick = () => {
    if (selectedCategory !== null) {
      setSelectedCategory(null);
      setSelectedOffre(null);
      setSelectedAllCategory(!selectedAllCategory)
      setTooltipMessage(
        "Sélectionnez une catégorie pour voir les offres correspondantes"
      );
    }
  };

  const getCommanderText = (type_offre) => {
    const typeMappings = {
      visio_conference: "Commander Visio",
      chat: "Commander Chat",
      call: "Commander Audio",
      emailing: "Commander Emailing",
    };
    return typeMappings[type_offre] || "Commander";
  };

  const filteredOffers = offres.filter(
    (offer) =>
      selectedCategory === null ||
      (offer.offre &&
        offer.offre.category &&
        offer.offre.category.id_category === selectedCategory)
  );

  return (
    <Container className={styles["container-offres"]}>
      {selectedOffre && (
        <InfosModal
          isOpen={openInfosModal}
          setOpenInfosModal={setOpenInfosModal}
          offre={selectedOffre}
        />
      )}
      <Typography
        variant="h4"
        style={{
          color: "var(--taupe-fonc, #66534D)",
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "19px",
          fontStyle: "normal",
          fontWeight: 400,
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        Homines est dierum quod consilium consilium idem gratulatio consilium
        Rei causa reprehendant decreverim pertinerent dignitatem et decrevi quam
        ex multa publicae Mario dignitatem mea causa quod consilium hominis Rei.
      </Typography>
      <div style={{ display: "flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
        <Tooltip title={tooltipMessage} placement="top" arrow style={{}}>
          <div
            className={styles["cl_add"]}
            onClick={handleAllCategoryClick}
            style={{
              color: selectedAllCategory ? "#fff" : "#66534d",
              backgroundColor: selectedAllCategory ? "#f3778f" : "#fff",
              cursor: selectedCategory !== null ? "pointer" : "default",
              marginRight:"10vh"
            }}
          >
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                margin: "3vh",
                fontSize: "15px",
              }}
            >
              Tous
            </Typography>
          </div>
        </Tooltip>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {Categories &&
            Categories.map((category) => (
              <Paper
                key={category.id_category}
                elevation={4}
                sx={{
                  p: 3,
                  color:
                    category.id_category === selectedCategory
                      ? "#fff"
                      : "#f3778f",
                  backgroundColor:
                    category.id_category === selectedCategory
                      ? "#f3778f"
                      : "#fff",
                  borderRadius: "20px",
                  cursor: "pointer",
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
                className={styles["category-tab"]}
                onClick={() => handleCategoryClick(category.id_category)}
              >
                {category.title_category}
              </Paper>
            ))}
        </Grid>
      </div>

      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {filteredOffers.length == 0 ? (
          <Alert severity="info">
            No offres available for the selected category.
          </Alert>
        ) : (
          filteredOffers.map((offre, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: "20px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <img
                  src={typeOffreImages[offre.type_offre]}
                  alt={`Offer ${offre.type_offre}`}
                />
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: 2,
                    marginBottom: 1,
                    color: "var(--taupe-fonc, #66534D)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "1rem",
                    width: "100%",
                  }}
                >
                  {offre.titre_offre}
                </Typography>
                <Button
                  className={styles["btn-commander-offre"]}
                  sx={{ textTransform: "capitalize", marginTop: "auto" }}
                  onClick={() => handleClickOpenInfosModal(offre)}
                >
                  {getCommanderText(offre.type_offre)}
                </Button>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}

export default Offres;
