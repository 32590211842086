import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import onlineIcon from "../../../../Assets/images/Online-icon-angelique-story.png";
import bienvenue_story from "../../../../Assets/images/bienvenue_story.png";
import "./Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Rating from "react-rating-stars-component";
import { useNavigate, NavLink } from "react-router-dom";
import GlobalContext from "../../../../Context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";
//import ScrollToTop from "../../../../Utils/ScrollToTop";

const GradientCardBody = styled(CardBody)`
  border-radius: 20px;
`;
const CardContainer = styled.div`
  margin: 0 auto; /* Adjust the margin to control the space between cards */
`;


function Header() {
  const navigate = useNavigate();
  const context = React.useContext(GlobalContext);

  const handleEnSavoirPlusClick = (id_user) => {
    context.setSelectedIntervenantId(id_user);
  };
 // Utilisation de la variable d'environnement pour l'URL des images de produit
 //const produitImageURL = process.env.REACT_APP_LARAVEL_PUBLIC_STORY_BACKEND_API_PRODUIT_IMAGE;
 //console.log('image',produitImageURL); // Vérifiez la valeur dans la console

 //const apiHost = process.env.REACT_APP_APIURL;
 //console.log('image2',apiHost); // Vérifiez la valeur dans la console

  const sliderSettings = {
    arrows: true,
    style: { maxWidth: "1100px", margin: "0 auto" },
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          style: {
            maxWidth: "100%",
            margin: "0 auto",
            maxHeight: "570px",
          },
        },
      },
    ],
  };
  if (!context.intervenantsData) {
    return (
      <section className="bg-header">
        <img src={bienvenue_story} alt="bienvenue_story" className="center" />

        <Slider {...sliderSettings}>
          {Array.from({ length: 3 }).map((_, index) => (
            <CardContainer key={index}>
              <Card style={{ width: "18rem" }} className="h-100">
                <Skeleton variant="rectangular" height={270} />
                <GradientCardBody>
                  <CardTitle tag="h5">
                    <Skeleton variant="text" width={100} />
                  </CardTitle>
                  <CardSubtitle tag="h6">
                    <Skeleton variant="text" width={80} />
                  </CardSubtitle>
                  <CardText
                    style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton variant="text" width={150} height={40} />
                  </CardText>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton variant="text" width={100} height={30} />
                  </div>
                </GradientCardBody>
              </Card>
            </CardContainer>
          ))}
        </Slider>
        <div className="div-more">
          <center>
            <button
              className="btn-more"
              onClick={() => navigate("/intervenants")}
              disabled={true}>
              Voir plus d’intervenants
            </button>
          </center>
        </div>
      </section>
    );
  }
  // Filter items to include only active users
  const onlineUsers = context.intervenantsData.filter(
    (item) => item.availability_user === 1
  );
 

  return (
    <div>
      <section className="bg-header">
        <img src={bienvenue_story} alt="bienvenue_story" className="center" />

        <Slider {...sliderSettings}>
          {onlineUsers.map((item) => (
            <div key={item.id_user}>
              <Card style={{ width: "18rem" }} className="h-100">
                {/* Show online icon if user is active */}
                {item.availability_user === 1 && (
                  <img
                    src={onlineIcon}
                    alt="Online"
                    className="online-icon"
                    style={{
                      width: "30%",
                      marginBottom: "-40px",
                      padding: "10px 0px 0px 10px",
                      zIndex: 1,
                    }}
                  />
                )}
                <img alt="Sample" src={`${process.env.REACT_APP_APIURL}/storage/workshop/${item.photo_user}`} />
                <GradientCardBody>
                  {/* Display star rating */}
                  <div
                    className="satisfaction-header"
                    style={{ marginBottom: "0px", textAlign: "end" }}>
                    <h6>
                      <b>Satisfaction clients</b>
                    </h6>
                  </div>
                  <div
                    className="custom-rating-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <p
                      className="text-medium"
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "26px",
                        fontWeight: 500,
                        lineHeight: "26px",
                        letterSpacing: "0em",
                        textAlign: "center", // Centrer le texte
                        marginRight: "10px", // Marge à droite pour séparer le texte des étoiles
                        color: "#66534D", // Couleur du texte

                        marginBottom: "0", // Supprimer la marge inférieure par défaut
                      }}>
                      4 / 5
                    </p>
                    <Rating
                      count={5}
                      value={4} // 4 étoiles colorées
                      size={20}
                      edit={true}
                      activeColor="#F3778F" // Couleur des étoiles colorées
                      inactiveColor="#C4C4C4" // Couleur des étoiles non sélectionnées
                      color="#FFFFFF" // Couleur de l'étoile blanche
                    />
                  </div>

                  <CardTitle
                    tag="h5"
                    style={{
                      color: "#66534D",
                      textAlign: "center",
                      fontSize: "26px",
                    }}>
                    <b>{item.nom_user}</b>
                  </CardTitle>
                  <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                    style={{
                      color: "#66534D",
                      textAlign: "center",
                      fontSize: "18px",
                    }}>
                    {item.main_speciality_user}
                  </CardSubtitle>

                  {/* Rest of your card content */}
                  <center>
                    {/* Inside your user card */}
                    <NavLink
                      to={`/intervenants/${item.nom_user}-${item.prenom_user}`}
                      className="btn btn-sm"
                      onClick={() => handleEnSavoirPlusClick(item.id_user)}>
                      En savoir +
                    </NavLink>
                  </center>
                </GradientCardBody>
              </Card>
            </div>
          ))}
        </Slider>
        <div className="div-more">
          <center>
            <button
              className="btn-more"
              onClick={() => navigate("/intervenants")}>
              Voir plus d’intervenants
            </button>
          </center>
        </div>
      </section>
    </div>
  );
}

export default Header;
