import React from "react";
import { Typography } from "@mui/material";
import "./mentions_legales.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function Mentions_legales() {

  return (
    <div className="div-mentions-legales">
      <section className="bg-section-mention-legales">
       
        <img src="" alt=""/>
        <Typography
                variant="h4"
                style={{
                  color: "#F3778F",
                  textAlign: "left",
                  fontFamily: "Majetto",
                  fontSize: "2.5vw",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  marginBottom: "50px",
                }}
              >
                Mentions Légales
              </Typography>

        <p>
            <b>Société :</b>Angélique HIRMANCE Adresse : 17 Rue de Broglie, 66330 Cabertany, Tel : 06 25 74 04 93 Site internet : 
            https://angeliquehirmancestory.fr Email : angemediumvoyante@gmail.com Création et réalisation du site.
        </p>
        <p>
            <b>Création et réalisation</b> du site <b>YANACOM SARL</b> 232 Rue A. Beau de Rochas 66000 PERPIGNAN FRANCE www.yanacom.fr Hébergement : OVH
        </p>
        <p>
            <b>Société OVH Siège social :</b> 2 rue Kellermann - 59100 Roubaix, France Tél: +33 (0)8 99 70 17 61 Site web : www.ovh.com Conditions d’utilisation</p>

        <p>
            Le site accessible par l'url suivant : http://142.4.217.124/Au-Boudoir-des-secrets est exploité dans le respect de la législation française. 
            L’utilisation de ce site est régie par les présentes conditions générales. En utilisant le site,
            vous reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Celles-ci pourront êtres modifiées à tout moment et sans préavis 
            par la société Angélique HIRMANCE. Angélique HIRMANCE ne saurait être tenu pour responsable en aucune manière d’une mauvaise utilisation du service.
        </p>
        <p>
            <b> Responsabilité</b>

            Aucune autre garantie n’est accordée au client, auquel incombe l’obligation de formuler clairement ses besoins et le devoir de s’informer. Si des informations fournies par Angélique HIRMANCE apparaissent inexactes, il appartiendra au client de procéder lui-même à toutes vérifications de la cohérence ou de la vraisemblance des résultats obtenus. Angélique HIRMANCE ne sera en aucune façon responsable vis à 
            vis des tiers de l’utilisation par le client des informations ou de leur absence contenues dans ses services y compris un de ses sites Internet.
        </p>

      </section>
      
    </div>





  );
}

export default Mentions_legales;
