// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Videos_btn-video__uN09N{
    border: 2px solid #8D7268 !important;
    background-color: white !important;
    color: #8D7268 !important;
    padding: 10px 50px!important;
    border-radius: 10px !important;
    font-size: 18px !important;
}

.Videos_no-videos-message-container__Q4\\+XB {
    width: 50%;
    margin: 0 auto;
    padding-top: 20px; 
  }
  
  .Videos_no-videos-message__9qnVA {
    text-align: center;
    display: flex;
    justify-content: center;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/Videos/Videos.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kCAAkC;IAClC,yBAAyB;IACzB,4BAA4B;IAC5B,8BAA8B;IAC9B,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".btn-video{\n    border: 2px solid #8D7268 !important;\n    background-color: white !important;\n    color: #8D7268 !important;\n    padding: 10px 50px!important;\n    border-radius: 10px !important;\n    font-size: 18px !important;\n}\n\n.no-videos-message-container {\n    width: 50%;\n    margin: 0 auto;\n    padding-top: 20px; \n  }\n  \n  .no-videos-message {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-video": `Videos_btn-video__uN09N`,
	"no-videos-message-container": `Videos_no-videos-message-container__Q4+XB`,
	"no-videos-message": `Videos_no-videos-message__9qnVA`
};
export default ___CSS_LOADER_EXPORT___;
