// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../Assets/images/Background.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../Assets/images/Background-reservation-profil.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../Assets/images/bg-angelique-hitmance-story.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileIntervenant_speciality-span__G-E2A{
    border: 2px solid #F6D1D8;
    border-radius: 30px;
    color: #F3778F;
    padding: 10px 20px;
    margin-right: 20px;
}
.ProfileIntervenant_description__R6wrq{
    padding-left: 50px;
}
.ProfileIntervenant_div-speciality__IBJ-N{
    margin-top: 30px;
}
.ProfileIntervenant_bg-section-boutique__oWN3S{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 20%;
}
section{
    background-color: white;
}
.ProfileIntervenant_section-reservation__QMC-i{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
    background-position: top;
    padding: 6% 10%;
}

.ProfileIntervenant_section-videos__jQ3Sz{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-repeat: no-repeat;
    background-position: bottom;
}
.ProfileIntervenant_section-description__eYQBW{
    padding: 10% 10% 100px 10%;
}
@media (max-width: 768px) {
    .ProfileIntervenant_typo-responsive__NWQqG {
      font-size: 1.5rem; 
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/ProfileIntervenant.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yDAA+D;IAC/D,4BAA4B;IAC5B,wBAAwB;IACxB,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,yDAAkF;IAClF,4BAA4B;IAC5B,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,yDAAgF;IAChF,4BAA4B;IAC5B,2BAA2B;AAC/B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".speciality-span{\n    border: 2px solid #F6D1D8;\n    border-radius: 30px;\n    color: #F3778F;\n    padding: 10px 20px;\n    margin-right: 20px;\n}\n.description{\n    padding-left: 50px;\n}\n.div-speciality{\n    margin-top: 30px;\n}\n.bg-section-boutique{\n    background-image: url(../../../../Assets/images/Background.png);\n    background-repeat: no-repeat;\n    background-position: top;\n    padding-top: 20%;\n}\nsection{\n    background-color: white;\n}\n.section-reservation{\n    background-image: url(../../../../Assets/images/Background-reservation-profil.png);\n    background-repeat: no-repeat;\n    background-position: top;\n    padding: 6% 10%;\n}\n\n.section-videos{\n    background-image: url(../../../../Assets/images/bg-angelique-hitmance-story.png);\n    background-repeat: no-repeat;\n    background-position: bottom;\n}\n.section-description{\n    padding: 10% 10% 100px 10%;\n}\n@media (max-width: 768px) {\n    .typo-responsive {\n      font-size: 1.5rem; \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"speciality-span": `ProfileIntervenant_speciality-span__G-E2A`,
	"description": `ProfileIntervenant_description__R6wrq`,
	"div-speciality": `ProfileIntervenant_div-speciality__IBJ-N`,
	"bg-section-boutique": `ProfileIntervenant_bg-section-boutique__oWN3S`,
	"section-reservation": `ProfileIntervenant_section-reservation__QMC-i`,
	"section-videos": `ProfileIntervenant_section-videos__jQ3Sz`,
	"section-description": `ProfileIntervenant_section-description__eYQBW`,
	"typo-responsive": `ProfileIntervenant_typo-responsive__NWQqG`
};
export default ___CSS_LOADER_EXPORT___;
