import React from 'react'
import { Container, Typography } from "@mui/material";
import SubscriptionCard from './Components/SubscriptionCard/SubscriptionCard';
import Pagination from "@mui/material/Pagination";
import styles from "./Subscriptions.module.css";

export default function Subscriptions(props) {
  if (!props.subs) {
    return (
      <Container>
        <Typography>No subscriptions found</Typography>
      </Container>
    );
  }
  return (
    <Container>
      <div className={styles["subsCards"]}>
        {props.subs.length > 0 ? (
          props.subs
            .slice((props.currentPage - 1) * 6, props.currentPage * 6)
            .map((subs) => <SubscriptionCard key={subs.id_subs} subs={subs} />)
        ) : (
          <Typography>No subscriptions found</Typography>
        )}
      </div>
      {props.subs.length > 0 && (
      <div className={styles["paginationContainer"]}>
        <Pagination
          count={Math.ceil(props.subs.length / 6)}
          page={props.currentPage}
          onChange={props.handlePageChange}
          style={{ marginTop: "3%" }}
        />
      </div>
    )}
    </Container>
  )
}
