// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Offres_btn-commander-offre__M0PKJ {
  border: 2px solid #66534d !important;
  background-color: #fff !important;
  color: #66534d !important;
  border-radius: 10px !important;
  font-size: 1.3rem !important;
  flex-wrap: wrap;
  border-radius: 10px !important;
  width: min-content;
}
.Offres_btn-commander-offre__M0PKJ:hover {
  background-color: #f3778f !important;
  border: 2px solid #f3778f !important;
  color: #fff !important;
}
.Offres_container-offres__MYTwG {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-around !important;
  height: 100%;
}
.Offres_no-offres-message-container__Ftaew {
  width: 50%;
  margin: 0 auto;
  padding-top: 20px;
}

.Offres_no-offres-message__CUbtd {
  text-align: center;
  display: flex;
  justify-content: center;
}

.Offres_cl_add__0j95y {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px #f3778f;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/Offres/Offres.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,iCAAiC;EACjC,yBAAyB;EACzB,8BAA8B;EAC9B,4BAA4B;EAC5B,eAAe;EACf,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,sBAAsB;AACxB;AACA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,8BAA8B;EAC9B,wCAAwC;EACxC,YAAY;AACd;AACA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".btn-commander-offre {\n  border: 2px solid #66534d !important;\n  background-color: #fff !important;\n  color: #66534d !important;\n  border-radius: 10px !important;\n  font-size: 1.3rem !important;\n  flex-wrap: wrap;\n  border-radius: 10px !important;\n  width: min-content;\n}\n.btn-commander-offre:hover {\n  background-color: #f3778f !important;\n  border: 2px solid #f3778f !important;\n  color: #fff !important;\n}\n.container-offres {\n  display: flex !important;\n  flex-direction: column !important;\n  align-items: center !important;\n  justify-content: space-around !important;\n  height: 100%;\n}\n.no-offres-message-container {\n  width: 50%;\n  margin: 0 auto;\n  padding-top: 20px;\n}\n\n.no-offres-message {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n}\n\n.cl_add {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  cursor: pointer;\n  border: solid 1px #f3778f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-commander-offre": `Offres_btn-commander-offre__M0PKJ`,
	"container-offres": `Offres_container-offres__MYTwG`,
	"no-offres-message-container": `Offres_no-offres-message-container__Ftaew`,
	"no-offres-message": `Offres_no-offres-message__CUbtd`,
	"cl_add": `Offres_cl_add__0j95y`
};
export default ___CSS_LOADER_EXPORT___;
