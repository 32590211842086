import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Utils/axios";
import { Button, useTheme } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import "./ClientProfile.css";
import mon_espace_perso from "../../Assets/images/mon-espace-personne-story (1).png";
import { FaFilePdf, FaEye } from "react-icons/fa";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from "@mui/material";
import GlobalContext from "../../Context/GlobalContext";

function ClientProfile() {
  const { id_client } = useParams();
  const context = React.useContext(GlobalContext);
  const [userData, setUserData] = useState(context.client);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // Declare openDialog here
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [currentPageCommands, setCurrentPageCommands] = useState(1);
  const [currentPageReservations, setCurrentPageReservations] = useState(1);
  const itemsPerPageReservation = 5; // You can adjust the number of items per page as needed
  // For reservations
  const indexOfLastItemReservations =
    currentPageReservations * itemsPerPageReservation;
  const indexOfFirstItemReservations =
    indexOfLastItemReservations - itemsPerPageReservation;
  const currentReservations = userData.reservations
    ? userData.reservations.slice(
        indexOfFirstItemReservations,
        indexOfLastItemReservations
      )
    : [];

  const itemsPerPageCommands = 10; // You can adjust the number of items per page as needed
  // For commands
  const indexOfLastItemCommands = currentPageCommands * itemsPerPageCommands;
  const indexOfFirstItemCommands =
    indexOfLastItemCommands - itemsPerPageCommands;
  const currentCommands = userData.commands
    ? userData.commands
        .filter((command) => command.etat_cmd === "SUCCESS")
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(indexOfFirstItemCommands, indexOfLastItemCommands)
    : [];

  // Function to open the dialog and set the selected reservation
  const openReservationDetails = (reservation) => {
    setSelectedReservation(reservation);
    setOpenDialog(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found in local storage");
        return;
      }

      // Send an authenticated PUT request to update user data
      await axios.put(`/client/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Profile updated successfully");
      window.location.reload();
      // Display a success message or perform any other actions after the update.
    } catch (error) {
      setError("Error updating user data: " + error.message);
    }
  };

  const fetchInvoicePDF = async (reference) => {
    try {
      // Make a GET request to the API endpoint with the command reference
      const response = await axios.get(`/offer/command/invoice/${reference}`, {
        responseType: "arraybuffer",
      });

      if (response.status === 200) {
        // Create a blob from the array buffer response
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new window
        window.open(url, "_blank");
      } else {
        alert("Error fetching the invoice.");
      }
    } catch (error) {
      console.error("Error fetching the invoice:", error);
      alert("Error fetching the invoice.");
    }
  };

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(
      undefined,
      options
    );
    return formattedDate.replace(",", " à");
  }

  const theme = useTheme();
  return (
    <section className="section-bg-profile">
      <div className="container">
        <div className="row">
          <img src={mon_espace_perso} alt="espace_perso" className="center" />
          <br></br>
        </div>
        <div className="row gutters-sm">
          <div className="col-md-4 ">
            <div className="card">
              <div className="card-body">
                <nav className="nav flex-column nav-pills nav-gap-y-1">
                  <a
                    href="#info"
                    data-toggle="tab"
                    className="nav-item nav-link has-icon nav-link-faded active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-user mr-2">
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    Informations du compte
                  </a>
                  <a
                    href="#profile"
                    data-toggle="tab"
                    className="nav-item nav-link has-icon nav-link-faded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-settings mr-2">
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    Paramètre du compte
                  </a>
                  <a
                    href="#commande"
                    data-toggle="tab"
                    className="nav-item nav-link has-icon nav-link-faded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-shield mr-2">
                      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                    </svg>
                    Commandes
                  </a>
                  <a
                    href="#reservation"
                    data-toggle="tab"
                    className="nav-item nav-link has-icon nav-link-faded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-bell mr-2">
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    Reservations
                  </a>
                  <br></br>
                  {/* <Button
                  style={{ cursor: 'pointer' }}
                  variant="contained"
                  size="small"
                  className='logout-btn'
                  onClick={handleLogout}
                >
                  Déconnexion
                </Button> */}
                </nav>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body tab-content">
                <div className="tab-pane active" id="info">
                  <h6>Informations du compte</h6>
                  <hr />
                  <p>
                    <strong>Nom:</strong> {userData.nom_client}
                  </p>
                  <p>
                    <strong>Prénom:</strong> {userData.prenom_client}
                  </p>
                  <p>
                    <strong>Email:</strong> {userData.email_client}
                  </p>
                  <p>
                    <strong>Adresse:</strong> {userData.addresse_client}
                  </p>
                  <p>
                    <strong>Ville:</strong> {userData.ville_client}
                  </p>
                  <p>
                    <strong>Code Postal:</strong> {userData.cp_client}
                  </p>
                  <p>
                    <strong>Province:</strong> {userData.province_client}
                  </p>
                  <p>
                    <strong>Téléphone:</strong> {userData.phone_client}
                  </p>
                
                  <p>
                    <strong>Vidéos restantes:</strong> {userData.nbr_video}
                  </p>
                  <p>
                    <strong>Offres restantes:</strong> {userData.nbr_offre}
                  </p>
                  <p>
                    <strong>Ateliers restants:</strong> {userData.nbr_workshops}
                  </p>
                </div>

                <div className="tab-pane" id="profile">
                  <h6>Paramètre du compte</h6>
                  <hr />
                  <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                      <label for="fullName">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        aria-describedby="fullNameHelp"
                        placeholder="Enter your fullname"
                        value={formData.nom_client || userData.nom_client}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            nom_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label for="fullName">Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        aria-describedby="fullNameHelp"
                        placeholder="Enter your fullname"
                        value={formData.prenom_client || userData.prenom_client}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            prenom_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label for="fullName">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        aria-describedby="fullNameHelp"
                        placeholder="Enter your fullname"
                        value={formData.email_client || userData.email_client}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            email_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label for="bio">Adresse</label>
                      <textarea
                        className="form-control autosize"
                        id="bio"
                        placeholder="Write something about you"
                        style={{
                          overflow: "hidden",
                          overflowWrap: "break-word",
                          resize: "none",
                          height: "62px",
                        }}
                        value={
                          formData.addresse_client || userData.addresse_client
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addresse_client: e.target.value,
                          })
                        }></textarea>
                    </div>
                    <div className="form-group">
                      <label for="url">Ville</label>
                      <input
                        type="text"
                        className="form-control"
                        id="url"
                        placeholder="Enter your website address"
                        value={formData.ville_client || userData.ville_client}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ville_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label for="location">Province</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Enter your location"
                        value={
                          formData.province_client || userData.province_client
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            province_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label for="location">Téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        placeholder="Enter your location"
                        value={formData.phone_client || userData.phone_client}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            phone_client: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button type="submit" className="btn-update">
                      Mettre à jour
                    </button>
                  </form>
                </div>

                <div className="tab-pane" id="commande">
                  <h6>Commandes</h6>
                  <hr />
                  {userData.commands ? (
                    <TableContainer component={Paper}>
                      <Table className="table table-striped">
                        <TableHead>
                          <TableRow>
                            <TableCell>Référence de commande</TableCell>
                            <TableCell>Date de création</TableCell>
                            <TableCell>Montant payé</TableCell>
                            <TableCell>Etat</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Facture</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentCommands.map((command) => (
                            <TableRow key={command.id_cmd}>
                              <TableCell>{command.ref_cmd}</TableCell>
                              <TableCell>
                                {formatDateTime(command.created_at)}
                              </TableCell>
                              <TableCell>{command.amount_paid} €</TableCell>
                              <TableCell>
                                {command.etat_cmd === "SUCCESS"
                                  ? "Validée"
                                  : command.etat_cmd}
                              </TableCell>
                              <TableCell>{command.type_cmd}</TableCell>
                              <TableCell>
                                <FaFilePdf
                                  onClick={() =>
                                    fetchInvoicePDF(command.ref_cmd)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <p>No command data available</p>
                  )}
                  <br></br>
                  <div className="pagination" style={{ float: "right" }}>
                    <Button
                      onClick={() =>
                        setCurrentPageCommands(currentPageCommands - 1)
                      }
                      disabled={currentPageCommands === 1}
                      style={{ cursor: "pointer" }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F3778F",
                        "&:hover": {
                          backgroundColor: "#66534d", // Background color on hover
                        },
                      }}>
                      Précédent
                    </Button>
                    <span>Page {currentPageCommands}</span>
                    <Button
                      onClick={() =>
                        setCurrentPageCommands(currentPageCommands + 1)
                      }
                      disabled={currentCommands.length < itemsPerPageCommands}
                      style={{ cursor: "pointer" }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F3778F",
                        "&:hover": {
                          backgroundColor: "#66534d", // Background color on hover
                        },
                      }}>
                      Suivant
                    </Button>
                  </div>
                </div>

                <div className="tab-pane" id="reservation">
                  <h6>Reservations</h6>
                  <hr />
                  {currentReservations.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table className="table table-striped">
                        <TableHead>
                          <TableRow>
                            <TableCell>Numéro</TableCell>
                            <TableCell>Offre</TableCell>
                            <TableCell>Date début</TableCell>
                            <TableCell>Date fin</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date de création</TableCell>
                            <TableCell>Détails</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentReservations.map((reservation) => (
                            <TableRow key={reservation.id_offre_reservation}>
                              <TableCell>
                                {reservation.id_offre_reservation}
                              </TableCell>
                              <TableCell>
                                {reservation.offre.info_offre.titre_offre}
                              </TableCell>
                              <TableCell>
                                {reservation.start_time_reservation}
                              </TableCell>
                              <TableCell>
                                {reservation.end_time_reservation}
                              </TableCell>
                              <TableCell>
                                {reservation.status_reservation}
                              </TableCell>
                              <TableCell>
                                {formatDateTime(reservation.created_at)}
                              </TableCell>
                              <TableCell>
                                <FaEye
                                  onClick={() =>
                                    openReservationDetails(reservation)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <p>No reservation data available</p>
                  )}
                  <br></br>
                  {/* Pagination */}
                  <div className="pagination" style={{ float: "right" }}>
                    <Button
                      onClick={() =>
                        setCurrentPageReservations(currentPageReservations - 1)
                      }
                      disabled={currentPageReservations === 1}
                      style={{ cursor: "pointer" }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F3778F",
                        "&:hover": {
                          backgroundColor: "#66534d", // Background color on hover
                        },
                      }}>
                      Précédent
                    </Button>
                    <span>Page {currentPageReservations}</span>
                    <Button
                      onClick={() =>
                        setCurrentPageReservations(currentPageReservations + 1)
                      }
                      disabled={
                        currentReservations.length < itemsPerPageReservation
                      }
                      style={{ cursor: "pointer" }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F3778F",
                        "&:hover": {
                          backgroundColor: "#66534d", // Background color on hover
                        },
                      }}>
                      Suivant
                    </Button>
                  </div>

                  {/* Reservation Details Dialog */}
                  <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Détail Reservation</DialogTitle>
                    <hr />
                    <DialogContent>
                      {selectedReservation && (
                        <div>
                          <p>
                            <b>Offre :</b>{" "}
                            {selectedReservation.offre.info_offre.titre_offre}
                          </p>
                          <p>
                            <b>Description :</b>{" "}
                            {
                              selectedReservation.offre.info_offre
                                .description_offre
                            }
                          </p>
                          <p>
                            <b>Type :</b>{" "}
                            {selectedReservation.offre.info_offre.type_offre}
                          </p>
                          <p>
                            <b>Prix :</b>{" "}
                            {selectedReservation.offre.info_offre.prix_offre}
                          </p>
                          <p>
                            <b>Durée :</b>{" "}
                            {selectedReservation.offre.info_offre.duree_offre}{" "}
                            minutes
                          </p>
                          <p>
                            <b>Paiement offre :</b>{" "}
                            {selectedReservation.offre.info_offre.is_paid_offre
                              ? "Payé"
                              : "Non payé"}
                          </p>
                          <p>
                            <b>Intervenant :</b>{" "}
                            {selectedReservation.user.nom_user}{" "}
                            {selectedReservation.user.prenom_user}
                          </p>
                        </div>
                      )}
                      <Button
                        onClick={() => setOpenDialog(false)}
                        variant="contained"
                        color="primary">
                        Fermer
                      </Button>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientProfile;
