// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonStyle {
    background-color: #66534d !important;
    color: #fbebee !important;
    transition: background-color 0.3s ease-in-out !important;
}
  
.buttonStyle:hover {
  background-color: #f6d1d8 !important;
  color: #66534d !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/PackvideoPage/Packvideo/Sidebar/FilterBar/Slider/PriceSlider.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,wDAAwD;AAC5D;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B","sourcesContent":[".buttonStyle {\n    background-color: #66534d !important;\n    color: #fbebee !important;\n    transition: background-color 0.3s ease-in-out !important;\n}\n  \n.buttonStyle:hover {\n  background-color: #f6d1d8 !important;\n  color: #66534d !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
