import React, { useState } from "react";
import { Col, Row, Form, FormGroup, Input } from "reactstrap";
import { Button, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "../../Utils/axios";
import classes from "./Footer.module.css";
import logo from "../../Assets/images/logo-footer-story.png";
import { NavLink } from "react-router-dom";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

function Footer() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/subscribe", formData);
      console.log(response.data);
      setFormSubmitted(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.email
      ) {
        const errorMessage = error.response.data.error.email[0];
        setErrorMessage(errorMessage);
        setErrorDialogOpen(true);
      } else {
        setErrorDialogOpen(true);
        setErrorMessage(
          "Une erreur s'est produite. Veuillez réessayer plus tard."
        );
      }
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  return (
<MDBFooter bgColor='light' className='text-center text-lg-start text-muted footer'>
  <section className=''>
    <MDBContainer className='text-center text-md-start mt-5'>
      <MDBRow className='mt-3'>
        <MDBCol md="3" lg="3" xl="3" className='mx-auto mb-4 word_wrap'>
          <img src={logo} alt="logo-angelique-story" className="img-fluid" />
          <p>
            « Donne tes mains pour servir et ton coeur pour aimer » Mère Teresa 
          </p>
          <br></br>
          <Typography
            variant="h4"
            style={{
              color: "#66534d",
              textAlign: "left",
              fontFamily: "Majetto",
              fontSize: "1.5vw",
              fontStyle: "normal",
              fontWeight: 400,
              textTransform: "uppercase",
            }}
          >
            Newsletter
          </Typography>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
              <Col sm={10}>
                <Input
                  id="email"
                  name="email"
                  placeholder="Votre adresse e-mail"
                  type="text"
                  onChange={handleChange}
                  value={formData.email}
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    marginTop: "20px",
                  }}
                />
              </Col>
            </FormGroup>
            <Button
              type="submit"
              style={{ backgroundColor: "#8d7268", color: "white" }}
            >
              Inscrivez-vous !
            </Button>
          </Form>
          <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
            <DialogTitle>Erreur</DialogTitle>
            <DialogContent>
              <Typography>{errorMessage}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErrorDialog} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </MDBCol>

        <MDBCol md="3" lg="3" xl="3" className='mx-auto mb-4 word_wrap'>
          <p className={classes.footerColumnTitle}>Informations</p>
          <p><i className="fa fa-map-marker"></i> 17 Rue de Broglie,
            66330 Cabertany, France
          </p>
          <p style={{wordBreak: "break-word"}}><i className="fas fa-envelope"></i> emailserviceclient@angeliquehirmancestory.fr</p>
          <p><i className="fas fa-phone-alt"></i> 0625740493</p>
        </MDBCol>

        <MDBCol md="3" lg="3" xl="3" className='mx-auto mb-4 word_wrap'>
          <p className={classes.footerColumnTitle}>La Plateforme</p>
          <NavLink to="#" className={classes.footerLink}>
            FAQ
          </NavLink><br></br>
          <NavLink to="#" className={classes.footerLink}>
            À propos
          </NavLink><br></br>
          <NavLink to="#" className={classes.footerLink}>
            Rejoignez-nous
          </NavLink><br></br>
          <NavLink to="#" className={classes.footerLink}>
            Contactez-nous
          </NavLink>
        </MDBCol>

        <MDBCol md="3" lg="3" xl="3" className='mx-auto mb-md-0 mb-4 word_wrap'>
          <p className={classes.footerColumnTitle}>
            <b>Infos Légales</b>
          </p>
          <NavLink to="#" className={classes.footerLink}>
            CGV
          </NavLink><br></br>
          <NavLink to="#" className={classes.footerLink}>
            Loi RGPD
          </NavLink><br></br>
          <NavLink to="/mentions_legales" className={classes.footerLink}>
            Mentions Légales
          </NavLink>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </section>

  <div className='text-center p-4' style={{ backgroundColor: '#fff' }}>
    <p className={classes.footertext}>
      Copyright © 2023 Angélique Hirmance Story | All Rights Reserved | 
      <a href="https://yanacom.fr/" target="_blank">Yanacom</a>
    </p>
  </div>
</MDBFooter>

  );
}

export default Footer;
