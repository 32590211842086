import React from "react";
import GlobalContext from "./GlobalContext";
import axios from "../Utils/axios";
import LocalStorage from "../Utils/localStorageService";
import LoadingComponent from "../components/Loading/LoadingComponent";
import { useNavigate } from "react-router-dom";

function AppContext(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    !!LocalStorage.getAccessToken()
  );
  const [intervenantsData, setIntervenantsData] = React.useState(null);
  const [selectedIntervenantProfile, setSelectedIntervenantProfile] =
    React.useState(null);
  const [SelectedIntervenantId, setSelectedIntervenantId] =
    React.useState(null);
  const [SelectedSubsId, setSelectedSubsId] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [subs, setSubs] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [videoPacks, setVideoPacks] = React.useState(null); // Ajouter état pour les packs vidéo
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const [subsResponse, intervenantsResponse, videoPacksResponse] = await Promise.all([
          axios.get("/public/subs/g/get"),
          axios.get("/public/intervenant/list/g/profile"),
          axios.get("/public/video-packs/get") // Ajouter requête pour récupérer les packs vidéo
        ]);
  
        setSubs(subsResponse.data.result);
        setIntervenantsData(intervenantsResponse.data.result);
        setVideoPacks(videoPacksResponse.data.result); // Mettre à jour l'état des packs vidéo
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (!subs || !intervenantsData || !videoPacks) {
      fetchData();
    }
  }, [subs, intervenantsData, videoPacks]);

  React.useEffect(() => {
    if (isLoggedIn && !client) {
      axios
        .get("/client/g/get/p/profile")
        .then((response) => {
          setClient(response.data.result);
          setLoading(false);
        })
        .catch((err) => {
          LocalStorage.clearAccessToken();
          setIsLoggedIn(false);
        });
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (SelectedIntervenantId) {
      const filteredIntervenants = intervenantsData.filter(
        (intervenant) => intervenant.id_user === SelectedIntervenantId
      );

      setSelectedIntervenantProfile(filteredIntervenants);

      localStorage.setItem(
        "selectedIntervenantProfile",
        JSON.stringify(filteredIntervenants)
      );
    }
  }, [SelectedIntervenantId, intervenantsData]);

  React.useEffect(() => {
    const fetchCategoriesAsync = async () => {
      try {
        const categoriesResponse = await axios.get("/public/g/cat/l/category");
        if (categoriesResponse.data.result) {
          setCategories(categoriesResponse.data.result);
          localStorage.setItem(
            "categories",
            JSON.stringify(categoriesResponse.data.result)
          );
        } else {
          console.error("erreur au niveau categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    !categories && fetchCategoriesAsync();
  }, [categories]);

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setLoading(true);
    axios
      .post("/public/c/client/d/disconnect")
      .then((response) => {
        response.status === 200
          ? (() => {
              LocalStorage.clearAccessToken();
              setIsLoggedIn(false);
              setClient(null);
              setLoading(false);
              navigate("/login");
            })()
          : console.error(
              "Logout failed:",
              response.data || response.statusText
            );
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  const contextValue = React.useMemo(
    () => ({
      isLoggedIn,
      client,
      login,
      logout,
      intervenantsData,
      subs,
      videoPacks, // Ajouter packs vidéo au contexte
      SelectedIntervenantId,
      setSelectedIntervenantId,
      SelectedSubsId,
      setSelectedSubsId,
      selectedIntervenantProfile,
      setSelectedIntervenantProfile,
      categories,
    }),
    [
      isLoggedIn,
      client,
      intervenantsData,
      subs,
      videoPacks, // Ajouter packs vidéo au contexte
      SelectedIntervenantId,
      SelectedSubsId,
      selectedIntervenantProfile,
      categories,
    ]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {loading ? <LoadingComponent loading /> : props.children}
    </GlobalContext.Provider>
  );
}

export default React.memo(AppContext);
