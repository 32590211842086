import React, { useState } from "react";
import axios from "../../Utils/axios";
import { Button, Typography, Alert } from "@mui/material";
import { Row, Form, FormGroup, Col, Input } from "reactstrap";
//import Header from '../../components/home/Header';
import contactez_nous from "../../Assets/images/contactez-nous-angelique-hirmance-story.png";
import pinAngelique from "../../Assets/images/pin-icon-contact-page-angelique-story.png";
import mailAngelique from "../../Assets/images/mail-icon-contact-page-angelique-story.png";
import phoneAngelique from "../../Assets/images/phone-icon-contact-page-angelique-story.png";
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    content: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/public/s/c/contact", formData);
      console.log(response.data); // Assuming your backend responds with a message
      // Handle success or display a success message to the user
      setFormSubmitted(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
      // Handle error or display an error message to the user
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <section className="bg-section-contact">
        <img src={contactez_nous} alt="bienvenue_story" className="center" />

        <Row style={{ paddingBottom: "15%" }}>
          <Col xs="12" className="col-info-box" style={{ paddingTop: "9%" }}>
            <Row>
              <Col xs="4">
                <Row>
                  <img
                    src={pinAngelique}
                    alt="bienvenue_story"
                    className="icon-width"
                    style={{width: "15%",
                      margin: "auto",
                      height: "auto"}}
                    
                  />
                  <Typography
                    variant="h4"
                    style={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                    }}
                  >
                    17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
                  </Typography>
                </Row>
                
              </Col>
              <Col xs="4">
                <Row>
                  <img
                    src={mailAngelique}
                    alt="bienvenue_story"
                    className="icon-width"
                    style={{width: "15%",
                      margin: "auto",
                      height: "auto"}}
                  />
                  <Typography
                    variant="h4"
                    style={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                    }}
                  >
                    serviceclient@angeliquehirmancestory.fr
                  </Typography>
                </Row>
                
              </Col>
              <Col xs="4">
                <Row>
                  <img
                    src={phoneAngelique}
                    alt="bienvenue_story"
                    className="icon-width"
                    style={{width: "15%",
                      margin: "auto",
                      height: "auto"}}
                  />
                  <Typography
                    variant="h4"
                    style={{
                      color: "#8d7268",
                      textAlign: "center",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      marginBottom: "50px",
                    }}
                  >
                    0625740493
                  </Typography>
                </Row>
                
              </Col>
            </Row>
          </Col>
          <Col xs="12" className="form-style">
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Nom complet"
                    type="text"
                    onChange={handleChange}
                    value={formData.name}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    onChange={handleChange}
                    value={formData.email}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="content"
                    name="content"
                    type="textarea"
                    onChange={handleChange}
                    value={formData.content}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>

              <Button
                type="submit"
                style={{ backgroundColor: "#8d7268", color: "white" }}
              >
                Envoyer !
              </Button>
            </Form>
            {formSubmitted && (
              <div style={{ marginTop: "20px" }}>
                <Alert severity="success">Message envoyée avec succès !</Alert>
                {refreshPage()} {/* Refresh the page when alert is displayed */}
              </div>
            )}
          </Col>
        </Row>
      </section>

      <section className="bg-section-contact-responsive-tablet">
        <img
          src={contactez_nous}
          alt="bienvenue_story"
          className="center"
          style={{ height: "auto", width: "100%" }}
        />
        <br></br>

        <Col xs="12" className="col-info-box">
          <Row>
            
              <Row>
                <img
                  src={pinAngelique}
                  alt="bienvenue_story"
                  className="icon-width"
                />
              
                <Typography
                  variant="h4"
                  style={{
                    color: "#8d7268",
                    textAlign: "center",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginBottom: "50px",
                  }}
                >
                   17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
                </Typography>
              </Row>
            
              <Row>
                <img
                  src={mailAngelique}
                  alt="bienvenue_story"
                  className="icon-width"
                />
              
                <Typography
                  variant="h4"
                  style={{
                    color: "#8d7268",
                    textAlign: "center",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginBottom: "50px",
                  }}
                >
                 serviceclient@angeliquehirmancestory.fr
                </Typography>
              </Row>
            
              <Row>
                <img
                  src={phoneAngelique}
                  alt="bienvenue_story"
                  className="icon-width"
                />
              
                <Typography
                  variant="h4"
                  style={{
                    color: "#8d7268",
                    textAlign: "center",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginBottom: "50px",
                  }}
                >
                  0625740493
                </Typography>
              </Row>
           
            <Col xs="12" className="form-style">
              <Form onSubmit={handleSubmit}>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Nom complet"
                      type="text"
                      onChange={handleChange}
                      value={formData.name}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      onChange={handleChange}
                      value={formData.email}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="content"
                      name="content"
                      type="textarea"
                      onChange={handleChange}
                      value={formData.content}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>

                <Button
                  type="submit"
                  style={{ backgroundColor: "#8d7268", color: "white" }}
                >
                  Envoyer !
                </Button>
                <br></br>
              </Form>

              {formSubmitted && (
                <div style={{ marginTop: "20px" }}>
                  <Alert severity="success">
                    Message envoyée avec succès !
                  </Alert>
                  {refreshPage()}{" "}
                  {/* Refresh the page when alert is displayed */}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </section>

      <section className="bg-section-contact-responsive-mobile">
        <img
          src={contactez_nous}
          alt="bienvenue_story"
          className="center"
          style={{ height: "auto", width: "100%" }}
        />
        <br></br>

        <Col>
          <Row>
            <img
              src={pinAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}
            >
               17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
            </Typography>
          </Row>
        </Col>
        <Col>
          <Row>
            <img
              src={mailAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}
            >
              serviceclient@angeliquehirmancestory.fr
            </Typography>
          </Row>
        </Col>
        <Col>
          <Row>
            <img
              src={phoneAngelique}
              alt="bienvenue_story"
              className="icon-width"
            />
          </Row>
          <Row>
            <Typography
              variant="h4"
              style={{
                color: "#8d7268",
                textAlign: "center",
                fontFamily: "Roboto, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                marginBottom: "50px",
              }}
            >
              0625740493
            </Typography>
          </Row>
        </Col>
        <Col xs="12" className="form-style">
          <Form>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="nom"
                  name="nom"
                  placeholder="Nom complet"
                  type="text"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  style={{
                    boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                  }}
                />
              </Col>
            </FormGroup>

            <Button style={{ backgroundColor: "#8d7268", color: "white" }}>
              Envoyer !
            </Button>
          </Form>
        </Col>
      </section>


    </div>
  );
}

export default Contact;
