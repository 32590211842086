import axios from 'axios';
import localStorageService from './localStorageService';

const apiHost = process.env.REACT_APP_APIURL;

const axiosInstance = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorageService.getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        localStorageService.clearAccessToken();
        window.location.href = "/login";
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
