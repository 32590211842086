// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Boutique_btn-more__klZra {
    border: 2px solid #66534D;
    background-color: transparent;
    color: #66534D;
    padding: 10px 28px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
}

.Boutique_boutique-section__mIs\\+i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/Boutique/Boutique.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,mBAAmB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[".btn-more {\n    border: 2px solid #66534D;\n    background-color: transparent;\n    color: #66534D;\n    padding: 10px 28px;\n    font-size: 16px;\n    cursor: pointer;\n    border-radius: 10px;\n}\n\n.boutique-section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  gap: 3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-more": `Boutique_btn-more__klZra`,
	"boutique-section": `Boutique_boutique-section__mIs+i`
};
export default ___CSS_LOADER_EXPORT___;
