import React from 'react';
import TitleSection from "./PackvideoTitle";
import PackvideoHome from '../../../PackvideoHome/PackvideoHome';
import "./CombinedPackvideoSection.css";
import backgroundImage from '../../../../../../../Assets/images/bg-abonnements-story.jpg';


const CombinedPackvideoSection = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover', // Adjust this property based on your needs
    // Add any other necessary styles
  };

  return (
    <div className="abonnement_background" style={backgroundStyle}>
      <TitleSection />
      <PackvideoHome />
    </div>
  );
};

export default CombinedPackvideoSection;