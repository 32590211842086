import React, { useState } from 'react';
import './SearchBar.css';

function SearchBar(props) {
  const [searchInput, setSearchInput] = useState(null);

  const handleSearch = () => {
    const filteredUsers = props.userData.filter((user) =>
        user.nom_user.toLowerCase().includes(searchInput.toLowerCase())
      );
    props.onFilter(filteredUsers);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Chercher un intervenant"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
      />
      <button onClick={handleSearch}>Chercher</button>
    </div>
  );
}

export default SearchBar;
