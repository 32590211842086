import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Button, Input, Spinner, Form } from "reactstrap";
import classes from "./LoginForm.module.css";
import axios from "../../Utils/axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { IoLockClosed } from "react-icons/io5";
import { BsAt } from "react-icons/bs";
import GlobalContext from "../../Context/GlobalContext";
import LocalStorage from "../../Utils/localStorageService";

function LoginForm() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const context = React.useContext(GlobalContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if(error) {
      toast.error('failed')
    }
  }, [error])
  

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post("/client/auth/login", userData)
      .then((response) => {
        setLoading(false);
        LocalStorage.setAccessToken(response.data.token);
        context.login();
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error)
        setError(true)
      });
  };

  return (
    <Row className={classes.formContainer}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Col xs={12} md={8} className="mx-auto" style={{ height: "50vh" }}>
        <div className={classes.horizontalBarContainer}>
          <div className={classes.horizontalBar}></div>
          <h4 className={classes.headerTitle}>CONNEXION</h4>
          <div className={classes.horizontalBar}></div>
        </div>
        <Form onSubmit={handleLogin}>
          <Row>
            <Col xs={12} md={6} className="mx-auto">
              <div className={`mb-3 ${classes.inputWithIcon}`}>
                <Input
                  id="exampleEmail"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    setUserData({ ...userData, email: e.target.value });
                  }}
                  className={classes.input}
                  placeholder="Adresse e-mail"
                />
                <span className={classes.icon}>
                  <BsAt />
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="mx-auto">
              <div className={`mb-3 ${classes.inputWithIcon}`}>
                <Input
                  id="pswd"
                  name="pswd"
                  type="password"
                  value={userData.password}
                  onChange={(e) => {
                    setUserData({ ...userData, password: e.target.value });
                  }}
                  className={classes.input}
                  placeholder="Mot de passe"
                />
                <span className={classes.icon}>
                  <IoLockClosed />
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} md={4} className="mx-auto">
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className={`btn ${classes.buttonStyle} btn-sm`}
                  type="submit"
                >
                  {loading ? (
                    <Spinner size="sm" className="mr-2" />
                  ) : (
                    <i className="fa-solid fa-right-to-bracket mr-2"></i>
                  )}
                  Se Connecter
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <Row className="mt-4 mb-4">
          <Col xs={12} md={6} className="mx-auto text-center">
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <h4 style={{ color: "rgb(130,130,130)", fontSize: "15px" }}>
                Mot de passe oublié
              </h4>
            </NavLink>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col xs={12} md={6} className="mx-auto text-center">
            <h4 style={{ color: "rgb(130,130,130)", fontSize: "15px" }}>
              Vous n'avez pas de compte ?{" "}
              <NavLink to="/" style={{ textDecoration: "none" }}>
                S'inscrire
              </NavLink>
            </h4>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default LoginForm;
