import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import frLocale from "date-fns/locale/fr";
import dayjs from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { ThemeProvider, createTheme } from "@mui/material";
import GlobalContext from "../../../../../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../../../Utils/axios";
import { toast, ToastContainer } from "react-toastify";
import LocalStorage from "../../../../../../../Utils/localStorageService";
import { Spinner } from "reactstrap";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        overline: {
          "&.MuiTypography-root": {
            display: "none",
          },
        },
      },
    },
  },
});

export default function Calendar(props) {
  const [dateRes, setDateRes] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [etatAbn, setEtatAbn] = useState(null);
  const [nbrOffre, setnbrOffre] = useState(null);
  const [idClient, setIdClient] = useState(null);
  const context = React.useContext(GlobalContext);
  const navigate = useNavigate();

  const handleCloseCalendar = () => {
    setDateRes(null);
    props.setOpenCalendar(false);
  };

  const shouldDisableDate = (day) => {
    try {
      const formattedDate = dayjs(day).startOf("day");
      const isDisabled = !props.availableDates.some((date) =>
        formattedDate.isSame(date, "day")
      );
      return isDisabled;
    } catch (error) {
      console.error("Error:", error);
      return true;
    }
  };
  const shouldDisableTime = (time) => {
    // Disable 3:00 PM on November 18th
    const disabledTime = dayjs("2023-11-18T16:00:00.000Z");
    return time.isSame(disabledTime, "minute");
  };
  const isDayAvailable = (date) => {
    const formattedDate = dayjs(`${date.year}-${date.month + 1}-${date.day}`);

    return props.availableDates.some((availableDate) => {
      const formattedAvailableDate = dayjs(availableDate).startOf("day");
      return formattedDate.isSame(formattedAvailableDate, "day");
    });
  };

  useEffect(() => {
    const fetchClientData = async () => {
      const token = localStorage.getItem("access_token_story"); // Adjust the key if necessary
      if (token) {
        try {
          const clientData = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
  
          if (clientData.data.status === "success") {
            const result = clientData.data.result;
            setEtatAbn(result.etat_abn);
            setIdClient(result.id_client);
            setnbrOffre(result.nbr_offre);
            console.log('Client data:', result);
          } else {
            console.error("Failed to fetch client data.");
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      }
    };
  
    fetchClientData(); // Call the async function
  }, []);  

  const handlePaiementReservation = () => {
    if (!context.isLoggedIn) {
      navigate("/signup");
      return;
    }
  
    if (!dateRes) {
      toast.error("Veuillez indiquer l'heure souhaitée pour votre réservation.");
      return;
    }
  
    setLoading(true);
  
    const formattedDateRes = dayjs(dateRes).format("YYYY-MM-DD HH:mm:ss");
    setDateRes(formattedDateRes);
  
    const token = LocalStorage.getAccessToken();
  
    // If etat_abn is 1, use the special API
if (etatAbn === 1 && nbrOffre !== 0) {
  const data = {
    id_offre: props.offre.offre.id_offre,  // Assuming id_offre is the correct parameter
    id_client: idClient,                  // Assuming this is the id of the client
    start_time_reservation: formattedDateRes,
  };
  axios
    .post(`${process.env.REACT_APP_APIURL}/process-order-offre-abonner/public/c`, data, {
      headers: {
        Authorization: `Bearer ${token}`,  
      },
    })
    .then((response) => {
      setLoading(false);
      toast.success("Votre réservation a été effectuée avec succès");
      // Redirect to the homepage with a success message
      const message = encodeURIComponent("Votre réservation a été effectuée avec succès ! Vous allez recevoir un message de confirmation de votre réservation.");
      window.location.href = `/message?message=${message}`;
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else if (error.request) {
        toast.error("No response from server. Please try again.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    });
}

    else if (etatAbn === 1 && nbrOffre === 0) {
      // Keep the current reservation method for users with etat_abn === 0
      const data = {
        itemToBuyId: props.offre.offre.id_offre,
        itemToBuyType: "OFFER",
        startTimeReservation: formattedDateRes,
        token: token,
      };
  
      axios
        .post(`${process.env.REACT_APP_BILLING_URL}/create-checkout-session`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error);
          } else if (error.request) {
            toast.error("No response from server. Please try again.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    } else if (etatAbn === 0 || etatAbn === null) {
      // Keep the current reservation method for users with etat_abn === 0
      const data = {
        itemToBuyId: props.offre.offre.id_offre,
        itemToBuyType: "OFFER",
        startTimeReservation: formattedDateRes,
        token: token,
      };
  
      axios
        .post(`${process.env.REACT_APP_BILLING_URL}/create-checkout-session`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error);
          } else if (error.request) {
            toast.error("No response from server. Please try again.");
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    } else {
      setLoading(false);
      toast.error("Invalid subscription status.");
    }
  };
  

  const ActionList = (props) => {
    const { className } = props;
    const actions = [
      { text: "Annuler", method: handleCloseCalendar },
      { text: "Réserver", method: handlePaiementReservation },
    ];

    return (
      <List
        className={className}
        style={{
          display: "flex",
          width: "50%",
          marginLeft: "auto",
          justifyContent: "flex-end",
        }}
      >
        {actions.map(({ text, method }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={method}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {text === "Réserver" && loading ? (
                <Spinner />
              ) : (
                <ListItemText primary={text} />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderDot = (borderColor, label, labelStyle = {}) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "0.2em" }}>
        <div
          style={{
            width: "1rem",
            height: "1rem",
            borderRadius: "50%",
            border: `0.125em solid ${borderColor}`,
            ...labelStyle,
          }}
        ></div>
        <span style={{fontSize:"0.9em"}}>{label}</span>
      </div>
    );
  }

  const renderToolBar = (props) => {
    return (
      <div style={{ display: "flex", justifyContent:"space-between", padding:"0.5em", flexWrap:"wrap"}}>
        {renderDot("#f3778f", "Disponible")}
        {renderDot("#ccc", "Disponibilité antérieure")}
        {renderDot("black", "Aujourd'hui")}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Dialog open={props.isOpen} onClose={handleCloseCalendar}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
            <ThemeProvider theme={theme}>
              <StaticDateTimePicker
                ampm={false} // Hide AM/PM indicator
                format="HH:mm" // Use 24-hour format
                slotProps={{
                  layout: {
                    sx: {
                      [`.${pickersLayoutClasses.actionBar}`]: {
                        color: "#f3778f",
                        gap: "2%",
                      },
                      "& .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root":
                        {
                          backgroundColor: "transparent",
                          border: "1px solid #F3778F",
                          color: "#F3778F",
                          borderRadius: "10px",
                          textAlign: "center",
                          padding: "0",
                          // Add any other styles you want to customize
                        },
                      "& .css-gwhxn1-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: "#f3778f !important",
                        },
                      "& .css-umzx0k-MuiClock-pin": {
                        backgroundColor: "#f3778f !important",
                      },
                      "& .css-d0vs79-MuiClockPointer-root": {
                        backgroundColor: "#f3778f !important",
                      },
                      "& .css-eg3pzz-MuiClockPointer-thumb": {
                        backgroundColor: "#f3778f !important",
                        borderColor: "#f3778f !important",
                      },
                      "& .css-12t0dn4-MuiClockPointer-thumb": {
                        borderColor: "#f3778f !important",
                      },
                      "& .css-rdq5h4-MuiClockPointer-root": {
                        backgroundColor: "#f3778f !important",
                      },
                      "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                        {
                          color: "#f3778f !important",
                        },
                      "& .css-1aquho2-MuiTabs-indicator": {
                        backgroundColor: "#f3778f !important",
                      },
                      "& .css-1bke2sa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: "#f3778f !important",
                        },
                      "& .css-innj4t-MuiPickersYear-yearButton.Mui-selected": {
                        backgroundColor: "#f3778f !important",
                      },
                      "& .css-innj4t-MuiPickersYear-yearButton.Mui": {
                        backgroundColor: "#f3778f !important",
                      },
                    },
                  },
                  day: (date) => {
                    const currentDate = dayjs();
                    const formattedDate = dayjs(
                      `${date.year}-${date.month + 1}-${date.day}`
                    ).startOf("day");

                    const isPastDate = formattedDate.isBefore(
                      currentDate,
                      "day"
                    );

                    return {
                      sx: {
                        ...(isDayAvailable(date) && {
                          border: isPastDate
                            ? "2px solid #ccc"
                            : "2px solid #f3778f",
                          backgroundColor: isPastDate ? "#eee" : "transparent",
                          color: isPastDate ? "#999" : "#f3778f",
                          "&:hover": {
                            color: isPastDate ? "#666" : "#fff",
                            outline: "none",
                            background: isPastDate ? "#eee" : "#f3778f",
                          },
                        }),
                      },
                    };
                  },
                }}
                slots={{
                  actionBar: ActionList,
                  calendarHeader: renderToolBar,
                }}
                orientation="landscape"
                disablePast
                onChange={(date) => {
                  setDateRes(date);
                }}
                onClose={handleCloseCalendar}
                shouldDisableDate={(date) => shouldDisableDate(date)}
                shouldDisableTime={shouldDisableTime}
                // Add other necessary props
              />
            </ThemeProvider>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
