import React from 'react';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check'; // Import the Check icon

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  fontWeight: 700,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background: `linear-gradient(136deg, rgba(243, 119, 143, 0.8) 0%, rgba(243, 119, 143, 0.8) 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
  }),
  ...(ownerState.completed && {
    background: `linear-gradient(136deg, rgba(246, 209, 216, 0.8) 0%, rgba(246, 209, 216, 0.8) 100%)`,
    boxShadow: '0 2px 5px rgba(246, 209, 216, 0.6)',
}),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {
        completed ? <Check className="QontoStepIcon-completedIcon" /> : icon
      }
        
    </ColorlibStepIconRoot>
  );
}

export default ColorlibStepIcon;