import React, { useContext } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import onlineIcon from '../../../../../Assets/images/Online-icon-angelique-story.png';
import offlineIcon from '../../../../../Assets/images/Offline-icon-angelique-story.png';
import { NavLink } from 'react-router-dom';
import GlobalContext from '../../../../../Context/GlobalContext';
import styles from './UserCard.module.css';
import Rating from '@mui/material/Rating';

function UserCard(props) {
  const { setSelectedIntervenantId } = useContext(GlobalContext);

  const isUserOnline = props.user.availability_user === 1;

  const handleEnSavoirPlusClick = () => {
    setSelectedIntervenantId(props.user.id_user);
  };

  return (
    <Card className={styles['user-card']}>
      {/* Show online or offline icon based on user's availability */}
      <img
        src={isUserOnline ? onlineIcon : offlineIcon}
        alt={isUserOnline ? 'Online' : 'Offline'}
        className={styles['availability-icon']}
      />
      <img alt="User" src={`${process.env.REACT_APP_APIURL}/storage/workshop/${props.user.photo_user}`} className={styles['user-photo']} />
      <CardBody className={styles['cardBody']}>
        {/* Display star rating */}
        <div className={styles['rating-section']}>
          <h6 className={styles['satisfaction-label']}>
            <b>Satisfaction clients</b>
          </h6>
          <Rating
            readOnly
            value={props.user.total_reviews_user / 20}
            activeColor="#F6D1D8"
            inactiveColor="#C4C4C4"
            color="#F4F4F4"
          />
        </div>
        <CardTitle tag="h6" className={styles['user-name']}>
          <b>{props.user.nom_user}</b>
        </CardTitle>
        <CardSubtitle className={styles['user-speciality']}>
          {props.user.main_speciality_user}
        </CardSubtitle>

        {/* En savoir plus button */}
        <div className={styles['en-savoir-plus']}>
          <NavLink
            to={`/intervenants/${props.user.nom_user}-${props.user.prenom_user}`}
            className="btn btn-sm"
            onClick={handleEnSavoirPlusClick}
          >
            En savoir +
          </NavLink>
        </div>
      </CardBody>
    </Card>
  );
}

export default UserCard;
