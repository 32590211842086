import React from 'react';

const GlobalContext = React.createContext({
    isLoggedIn: null,
    intervenantsData: null,
    subs: null,
    videoPacks: null, // Ajouté pour stocker les packs vidéo
    SelectedIntervenantId: null,
    selectedIntervenantProfile: null,
    SelectedSubsId: null,
    categories: null,
    client: null,
    setSelectedIntervenantId: () => {},
    setSelectedIntervenantProfile: () => {},
    setSelectedSubsId: () => {},
    setVideoPacks: () => {}, // Ajouté pour mettre à jour les packs vidéo
    login: () => {},
    logout: () => {},
});

export default GlobalContext;
