import React from "react";
import { useLocation } from "react-router-dom";

function MessageDisplay() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get("message");

  console.log("location.search:", location.search);
  console.log("message:", message);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      {message && (
        <div className="message" style={{ backgroundColor: "#F3778F", padding: "12px", borderRadius: "8px" }}>
          <p style={{ color: "#66534d", fontWeight: "bold" }}>{message}</p>
        </div>
      )}
    </div>
  );
}

export default MessageDisplay;
