// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-section-mention-legale{
    text-align: justify;
    padding: 20%;
}
.div-mentions-legales{
    padding: 70px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Info_legales/mention_legale/mentions_legales.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,aAAa;AACjB","sourcesContent":[".bg-section-mention-legale{\n    text-align: justify;\n    padding: 20%;\n}\n.div-mentions-legales{\n    padding: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
