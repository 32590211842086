import React from "react";
import { Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import classes from "./SubscriptionsPage.module.css";
import Subscriptions from "./Subscriptions/Subscriptions";
import Sidebar from "./Subscriptions/Sidebar/Sidebar";
import GlobalContext from "../../../../Context/GlobalContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SubscriptionsPage() {
  const context = React.useContext(GlobalContext);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [filteredSubs, setFilteredSubs] = React.useState(context.subs || []);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sliderPriceValues, setSliderPriceValues] = React.useState([10, 150]);
  
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    let updatedSubs = Array.isArray(context.subs) ? context.subs : [];
    // Filter by name
    if (searchTerm) {
      updatedSubs = updatedSubs.filter((sub) =>
        sub.name_subs.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    // Filter by price
    updatedSubs = updatedSubs.filter(
      (sub) =>
        sub.price_subs >= sliderPriceValues[0] && sub.price_subs <= sliderPriceValues[1]
    );
  
    setFilteredSubs(updatedSubs);
  }, [searchTerm, sliderPriceValues]);

  return (
    <Grid container className={classes.SubsPage}>
      <Grid item xs={12} md={2}>
        <Sidebar
          subs={context.subs}
          sliderPriceValues={sliderPriceValues}
          setSliderPriceValues={setSliderPriceValues}
          subsCount={filteredSubs.length}
          setSearchTerm={setSearchTerm}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Subscriptions
          subs={filteredSubs}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </Grid>
  );
}
