import React from "react";
import "./FilterBar.css";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PriceSlider from "./Slider/PriceSlider";
import Box from "@mui/material/Box";

function FilterBar(props) {
  const [openPrice, setOpenPrice] = React.useState("0");

  React.useEffect(() => {}, []);

  const togglePrice = (id) => {
    setOpenPrice((prevState) => (prevState === id ? null : id));
  };

  /*   const handlePriceChange = () => {};
   */
  return (
    <div className="filter-bar">
      <h5>Filter</h5>
      <Accordion
        open={openPrice}
        toggle={togglePrice}
        className="mb-3"
        style={{ height: "100%" }}
      >
        <AccordionItem>
          <AccordionHeader targetId="1">Prix</AccordionHeader>
          <AccordionBody accordionId="1" style={{}}>
            <Box sx={{ height: "100%" }}>
              <PriceSlider
                subs={props.subs}
                sliderPriceValues={props.sliderPriceValues}
                setSliderPriceValues={props.setSliderPriceValues}
                subsCount={props.subsCount}
              />
            </Box>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default FilterBar;
