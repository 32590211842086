// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../Assets/images/bg-abonnements-story.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionsPage_SubsPage__EkyiP {
    display: flex;
    justify-content: center;
    padding: 5% 0;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: bottom;
  }
  body {
    background-color: #fff;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/SubscriptionsPage/SubscriptionsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,yDAAyE;IACzE,4BAA4B;IAC5B,2BAA2B;EAC7B;EACA;IACE,sBAAsB;EACxB","sourcesContent":[".SubsPage {\n    display: flex;\n    justify-content: center;\n    padding: 5% 0;\n    background-image: url(../../../../Assets/images/bg-abonnements-story.jpg);\n    background-repeat: no-repeat;\n    background-position: bottom;\n  }\n  body {\n    background-color: #fff;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubsPage": `SubscriptionsPage_SubsPage__EkyiP`
};
export default ___CSS_LOADER_EXPORT___;
