// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/images/bg-angelique-hitmance-story.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-section-apropos{
    text-align: center;
    padding: 10% 10% 20% 10%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: bottom;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/A-propos/APropos.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,yDAA4E;IAC5E,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".bg-section-apropos{\n    text-align: center;\n    padding: 10% 10% 20% 10%;\n    background-image: url(\"../../Assets/images/bg-angelique-hitmance-story.png\");\n    background-repeat: no-repeat;\n    background-position: bottom;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
