// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingComponent_mainContainer__vdY28 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.LoadingComponent_logoImage__\\+Ht3f {
    width: 10em;
    margin: auto;
    animation: LoadingComponent_spin__P\\+FAx 1s linear infinite;
    transition: all .3 linear;
    transform: scale(1);
}

@keyframes LoadingComponent_spin__P\\+FAx {
    100% {

        transform: scale(1.1);
    }

    75% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1.3);
    }

    25% {
        transform: scale(1.2);
    }

    0% {
        transform: scale(1.1);
    }
}


`, "",{"version":3,"sources":["webpack://./src/components/Loading/LoadingComponent.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,YAAY;IAGZ,2DAAkC;IAClC,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI;;QAEI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".mainContainer {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 900;\n    backdrop-filter: blur(3px);\n}\n\n.logoImage {\n    width: 10em;\n    margin: auto;\n    -webkit-animation: spin 1s linear infinite;\n    -moz-animation: spin 1s linear infinite;\n    animation: spin 1s linear infinite;\n    transition: all .3 linear;\n    transform: scale(1);\n}\n\n@keyframes spin {\n    100% {\n\n        transform: scale(1.1);\n    }\n\n    75% {\n        transform: scale(1.2);\n    }\n\n    50% {\n        transform: scale(1.3);\n    }\n\n    25% {\n        transform: scale(1.2);\n    }\n\n    0% {\n        transform: scale(1.1);\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `LoadingComponent_mainContainer__vdY28`,
	"logoImage": `LoadingComponent_logoImage__+Ht3f`,
	"spin": `LoadingComponent_spin__P+FAx`
};
export default ___CSS_LOADER_EXPORT___;
