// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfosModal_content-text__l2O-- {
    display: flex;
    align-items: center;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/ProfileIntervenant/Offres/InfosModel/InfosModal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".content-text {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-text": `InfosModal_content-text__l2O--`
};
export default ___CSS_LOADER_EXPORT___;
