// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchBar.css */
.search-bar {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-bar input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px;
  }
  
  .search-bar button {
    background-color: #66534d;
    color: #fbebee;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 100%;
}
  
.search-bar button:hover {
  background-color: #f6d1d8;
  color: #66534d;
}
  `, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/SubscriptionsPage/Subscriptions/Sidebar/SearchBar/SearchBar.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;EAC1C;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,6CAA6C;IAC7C,WAAW;AACf;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":["/* SearchBar.css */\n.search-bar {\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .search-bar input {\n    border: none;\n    outline: none;\n    width: 100%;\n    padding: 8px;\n  }\n  \n  .search-bar button {\n    background-color: #66534d;\n    color: #fbebee;\n    border: none;\n    padding: 8px 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease-in-out;\n    width: 100%;\n}\n  \n.search-bar button:hover {\n  background-color: #f6d1d8;\n  color: #66534d;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
