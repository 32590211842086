import React from "react";
import { Container, Typography } from "@mui/material";
import UserCard from "./Components/UserCard/UserCard";
import Pagination from "@mui/material/Pagination";
import styles from "./Intervenants.module.css";
import ScrollToTop from "../../../Utils/ScrollToTop";

export default function Intervenants(props) {
  return (
    <Container>
      <div className={styles["userCards"]}>
        {props.users.length > 0 ? (
          props.users
            .slice((props.currentPage - 1) * 6, props.currentPage * 6)
            .map((user) => <UserCard key={user.id_user} user={user} />)
        ) : (
          <Typography>No intervenants found</Typography>
        )}
      </div>
      {props.users.length > 0 && (
      <div className={styles["paginationContainer"]}>
        <Pagination
          count={Math.ceil(props.users.length / 6)}
          page={props.currentPage}
          onChange={props.handlePageChange}
          style={{ marginTop: "3%" }}
        />
      </div>
    )}
    </Container>
  );
}
