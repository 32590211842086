import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';
import GlobalContext from '../../Context/GlobalContext';
import { Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import classes from './IntervenantsPage.module.css';
import { useLocation } from 'react-router-dom';
import Intervenants from './Intervenants/Intervenants'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function IntervenantsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [error, setError] = useState(null);

  const context = React.useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    if (context.intervenantsData) {
      setFilteredUsers(context.intervenantsData);

      const specialityIds = new Set();
      const allSpecialities = [];

      context.intervenantsData.forEach((user) => {
        if (user.specialities && user.specialities.length > 0) {
          user.specialities.forEach((speciality) => {
            if (!specialityIds.has(speciality.id)) {
              specialityIds.add(speciality.id);
              allSpecialities.push(speciality);
            }
          });
        }
      });
      setSpecialities(allSpecialities);
    }

    const locationError = location.state?.error;
    if (locationError) {
      setError(locationError);
      // Clear the error in the location state
      const updatedLocation = { ...location };
      delete updatedLocation.state.error;
      window.history.replaceState({}, '', updatedLocation.pathname);
    }
  }, [context.intervenantsData, location]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Grid container className={classes.intervenantsPage}>
      <Grid item xs={12} md={2}>
        <Sidebar onFilter={setFilteredUsers} userData={context.intervenantsData} specialities={specialities} />
      </Grid>
      <Grid item xs={12} md={6} >
        <Intervenants users={filteredUsers} handlePageChange={handlePageChange} currentPage={currentPage}/>
      </Grid>
    </Grid>
  );
}
