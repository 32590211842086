import styles from './Videos.module.css';
import { Grid, Button, Dialog, DialogContent, Alert } from '@mui/material';
import videoGratuite from '../../../../../Assets/images/Vidéo-gratuite.png';
import GlobalContext from "../../../../../Context/GlobalContext";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Videos({ userData }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [showAbonnementMessage, setShowAbonnementMessage] = useState(false);
  const [showPackMessage, setShowPackMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const context = React.useContext(GlobalContext);

  const handleCloseLogin = () => setShowLoginMessage(false);
  const handleCloseAbonnement = () => setShowAbonnementMessage(false);
  const handleClosePack = () => setShowPackMessage(false);
  const handleCloseVideoModal = () => setShowVideoModal(false);

  const handleVideoClick = async (videoSrc, client_id, video_id) => {

    const token = localStorage.getItem("access_token_story");
    if (!token) {
      setShowLoginMessage(true);
      setLoading(false);
      return;
    }
    // Check if video is already unlocked
    const unlockedVideos = JSON.parse(localStorage.getItem('unlockedVideos')) || [];
    if (unlockedVideos.includes(video_id)) {
      const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === "success") {
        setClientData(response.data.result);
        console.log('Client data:', response.data.result);
      } else {
        console.error("Failed to fetch client data.");
        setLoading(false);
        return;
      }

      // Check subscription and pack status
      if (response.data.result.etat_abn !== 1) {
        setShowAbonnementMessage(true);
        setLoading(false);
        return;
      } else if (response.data.result.nbr_video === 0) {
        setShowPackMessage(true);
        setLoading(false);
        return;
      }
      else 
      setSelectedVideoId(video_id);
      setShowVideoModal(true);}
  
      try {
        const decreaseResponse = await axios.put(`${process.env.REACT_APP_APIURL}/client/${client_id}/decrease-video-count`, null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        if (decreaseResponse.data.status === 'success') {
          console.log('Video count decreased:', decreaseResponse.data.remaining_videos);
        } else {
          console.error('Error:', decreaseResponse.data.message);
        }
      } catch (error) {
        console.error('Error decreasing video count:', error);
      }
  };

  

  const handleVideoFree = (videoSrc) => {
    setSelectedVideo(videoSrc);
  };

  // Method to just show the video without unlocking (no API call)
const showVideo = (video_id) => {
  
    const video = userData.library_videos.find(v => v.id_video_lbrv === video_id);
    
    if (video) {
      setSelectedVideo(video.file_name_lbrv);
      setIsUnlocked(true);
      setShowVideoModal(true);  // Show the modal with the video
    } else {
      console.error('Video not found for id:', video_id);
    }
};
  

  useEffect(() => {
    console.log("selectedVideo updated to:", selectedVideo);
  }, [selectedVideo]);

  useEffect(() => {
    console.log("userData.library_videos:", userData.library_videos);
  }, [userData]);

 

  const handleVideoViews = async (client_id, video_id) => {
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}/unlock-video`, null, {
        params: {
          client_id,
          video_id
        }
      });

      if (response.data.status === 'success') {
        console.log('Video unlocked successfully');
        setIsUnlocked(true);
      } else {
        console.error('Failed to unlock the video:', response.data.message);
      }

    } catch (error) {
      console.error('An error occurred while unlocking the video:', error);
    } finally {
      setLoading(false);
    }
  };

  // Method to unlock the video and update localStorage
  const handleUnlockVideoClick = async (client_id, video_id) => {
    console.log('Checking if video is unlocked for client_id:', client_id, 'and video_id:', video_id);
    
    // Set loading state
    setLoading(true);
  
    const token = localStorage.getItem("access_token_story");
    if (!token) {
      setShowLoginMessage(true);
      setLoading(false);
      return;
    }
  
    try {
      // Try to check if the video is already unlocked using the API route
      const isUnlockedResponse = await axios.get(`${process.env.REACT_APP_APIURL}/client/${client_id}/video/${video_id}/is-unlocked`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Log the API response
      console.log('Video unlocked status response:', isUnlockedResponse.data); 

      // If the video is already unlocked, show the modal directly
      if (isUnlockedResponse.data.is_unlocked) {
        console.log('Video is already unlocked.');
        setIsUnlocked(true);
        setSelectedVideoId(video_id);
        setShowVideoModal(true); // Show the modal directly
        setLoading(false); // Stop loading

        // Save unlocked video to local storage
      const unlockedVideos = JSON.parse(localStorage.getItem('unlockedVideos')) || [];
      if (!unlockedVideos.includes(video_id)) {
        unlockedVideos.push(video_id);
        localStorage.setItem('unlockedVideos', JSON.stringify(unlockedVideos));
      }

        return;
      }

    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Video is not unlocked, proceed to unlock it
        console.log("Video is locked, attempting to unlock...");

        // Fetch client data
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.status === "success") {
          setClientData(response.data.result);
          console.log('Client data:', response.data.result);
        } else {
          console.error("Failed to fetch client data.");
          setLoading(false);
          return;
        }

        // Check subscription and pack status
        if (response.data.result.etat_abn !== 1) {
          setShowAbonnementMessage(true);
          setLoading(false);
          return;
        } else if (response.data.result.nbr_video === 0) {
          setShowPackMessage(true);
          setLoading(false);
          return;
        }

        // Proceed to unlock the video
        try {
          console.log('Making unlock video API call for client:', client_id, 'video:', video_id);

          // Make the unlock video API call
          const unlockResponse = await axios.post(`${process.env.REACT_APP_APIURL}/unlock-video`, null, {
            params: {
              client_id,
              video_id
            }
          });

          console.log('Unlock video response:', unlockResponse.data);

          if (unlockResponse.data.status === 'success') {
            setIsUnlocked(true);
            setSelectedVideoId(video_id);
            setShowVideoModal(true);

                    // Save unlocked video to local storage
        const unlockedVideos = JSON.parse(localStorage.getItem('unlockedVideos')) || [];
        if (!unlockedVideos.includes(video_id)) {
          unlockedVideos.push(video_id);
          localStorage.setItem('unlockedVideos', JSON.stringify(unlockedVideos));
        }


          } else {
            console.error('Failed to unlock the video:', unlockResponse.data.message);
          }

        } catch (unlockError) {
          console.error('An error occurred while unlocking the video:', unlockError.response ? unlockError.response.data : unlockError.message);
        }
      } else {
        console.error("An error occurred while checking if the video is unlocked:", error.response ? error.response.data : error.message);
      }
    } finally {
      setLoading(false);
    }
};


  

  useEffect(() => {
  // Fetch client data when the component mounts
  const fetchClientData = async () => {
    try {
      const token = localStorage.getItem("access_token_story");
      if (!token) return;

      const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === "success") {
        setClientData(response.data.result);
      }

      // Check local storage for unlocked videos
      const unlockedVideos = JSON.parse(localStorage.getItem('unlockedVideos')) || [];
      
      if (userData.library_videos) {
        userData.library_videos.forEach((video) => {
          if (unlockedVideos.includes(video.id_video_lbrv)) {
            // If the video is found in localStorage, mark it as unlocked
            setIsUnlocked(true);
          }
        });
      }
      
    } catch (error) {
      console.error("An error occurred while fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  fetchClientData();
}, [userData]);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData || !userData.library_videos || userData.library_videos.length === 0) {
    return (
      <div className={styles["no-videos-message-container"]}>
        <Alert className={styles["no-videos-message"]} severity="info">
          Pas de vidéo disponible pour le moment
        </Alert>
      </div>
    );
  }

  return (
    <div style={{ padding: '50px 0' }}>
      <Grid container spacing={2} justifyContent="center">
        {userData.library_videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={video.id_video_lbrv}>
            <img src={videoGratuite} alt='' style={{ marginBottom: '20px', width: '100%', height: 'auto' }} />
            <center>
              {video.is_paid_video_lbrv === 1 ? (
                <div>
                  {isUnlocked ? (
                    <Button
                      className={styles['btn-video']}
                      variant="outlined"
                      onClick={() => handleVideoClick(video.file_name_lbrv, clientData.id_client, video.id_video_lbrv)}
                    >
                      Visionner la vidéo payante
                    </Button>
                  ) : (
                    <Button
                      className={styles['btn-video']}
                      variant="outlined"
                      onClick={() => handleUnlockVideoClick(clientData.id_client, video.id_video_lbrv)}
                    >
                      Déverrouiller la vidéo payante
                    </Button>
                  )}
                </div>
              ) : (
                <Button
                  className={styles['btn-video']}
                  variant="outlined"
                  onClick={() => handleVideoFree(video.file_name_lbrv)}
                >
                  Visionner la vidéo gratuite
                </Button>
              )}
            </center>
          </Grid>
        ))}
      </Grid>

      <Modal show={showVideoModal} onHide={handleCloseVideoModal}>
        <Modal.Header closeButton>
          <Modal.Title>Nombre de vue restante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Vous avez {clientData ? clientData.nbr_video : 0} nombre de vue restantes pour cette vidéo</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVideoModal}>Fermer</Button>
          <Button
            variant="primary"
            onClick={() => showVideo(selectedVideoId)} // Pass selectedVideoId here
          >
            Montrer la vidéo
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog open={Boolean(selectedVideo)} onClose={() => setSelectedVideo(null)} maxWidth="lg" fullWidth>
        <DialogContent>
          <video controls autoPlay style={{ width: '100%', height: 'auto' }}>
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>

      <Modal show={showLoginMessage} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Message de connexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Veuillez vous connecter pour voir cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogin}>Fermer</Button>
          <Link to="/login">
            <Button variant="primary">Connexion</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Modal show={showAbonnementMessage} onHide={handleCloseAbonnement}>
        <Modal.Header closeButton>
          <Modal.Title>Abonnement requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Vous devez avoir un abonnement pour regarder cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAbonnement}>Fermer</Button>
          <Link to="/subscriptions">
            <Button variant="primary">Acheter un abonnement</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Modal show={showPackMessage} onHide={handleClosePack}>
        <Modal.Header closeButton>
          <Modal.Title>Pack requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Vous devez acheter un nouveau pack pour regarder cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePack}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Videos;
