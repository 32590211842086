import React from 'react';
import FilterBar from './FilterBar/FilterBar';
import SearchBar from './SearchBar/SearchBar';
import styles from "./Sidebar.module.css"

function Sidebar(props) {
  return (
    <div className={styles['sidebar']}>
      <SearchBar 
        userData={props.userData}
        onFilter={props.onFilter} />
      <FilterBar 
        userData={props.userData}
        specialities={props.specialities} 
        onFilter={props.onFilter}
      />
    </div>
  );
}
export default Sidebar;
