import React from "react";
import { Typography } from "@mui/material";
import "./APropos.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function Mentions_legales() {

  return (
    <div className="div-apropos">
      <section className="bg-section-apropos">
       
        <img src="" alt=""/>
        <Typography
                variant="h4"
                style={{
                  color: "#F3778F",
                  textAlign: "center",
                  fontFamily: "Majetto",
                  fontSize: "2.5vw",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  marginBottom: "50px",
                }}
              >
                À PROPOS
              </Typography>

        <p>
        Bonjour à tous, je suis Angélique Médium...
        </p>
        <p>
        Depuis toujours, j'ai la chance d'avoir un lien privilégié avec l'au-delà, les Archanges, ce qui m'a permis bien des choses pour moi-même et surtout pour les autres...        </p>
        <p>
        Ayant une maman Médium, ainsi que mes sœurs, j'ai longtemps pensé que ressentir les choses, aussi bien pour soi-même que pour les autres, prévoir l'avenir possible et prévenir des éventualités étaient tout à fait normal...
        </p>

        <p>
        C'est en grandissant que je me suis aperçue de la chance que nous avions... En aidant davantage, et de plus en plus de personnes, j'ai pris conscience que partager cela était mon chemin de vie...
        </p>
        <p>
        C'est avec joie que je partagerai et vous aiderai dans votre vie avec Amour, Respect et Bienveillance.
        </p>
        <p>Bienvenue à vous!</p>

      </section>
      
    </div>





  );
}

export default Mentions_legales;
