import React, { useState } from "react";
import {
  CssBaseline,
  Container,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  useTheme,
  ThemeProvider
} from "@mui/material";
import { styled } from '@mui/material/styles';
import backgroundCalcul from "../../../../Assets/images/bg-calcul-numerologique-angelique-hirmance-story.png";
import { createTheme } from "@mui/material/styles"; // Import createTheme
const NumerologyCalculator = () => {
  const [showResults, setShowResults] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState("");
  const [resultPath, setresultPath] = useState("");

  const letterValues = {
    '1': ['A', 'J', 'S', 'É', 'È', 'Ê' ,'Ç'],
    '2': ['B', 'K', 'T', 'Ö'],
    '3': ['C', 'L', 'U', 'Ù', 'Ú', 'Û'],
    '4': ['D', 'M', 'V'],
    '5': ['E', 'N', 'W', 'Ë', 'Î'],
    '6': ['F', 'O', 'X', 'Ä', 'Ô'],
    '7': ['G', 'P', 'Y'],
    '8': ['H', 'Q', 'Z', 'Ü'],
    '9': ['I', 'R', 'À', 'Â', 'Ï']
  };

  const handleCalculate = (inputValue) => {
    const cleanedInput = inputValue
      .replace(/[^A-ZéèçöùúûëÎäôäôüàâïê]/gi, "")
      .toUpperCase();
    let numericValue = 0;
    let concatenatedResults = [];
    for (let i = 0; i < cleanedInput.length; i++) {
      const currentLetter = cleanedInput.charAt(i);

      for (const [value, letters] of Object.entries(letterValues)) {
        if (letters.includes(currentLetter)) {
          numericValue += parseInt(value);
          break;
        }
      }
    }
    concatenatedResults.push(numericValue);
    while (numericValue > 9) {
      numericValue = Array.from(String(numericValue), Number).reduce(
        (sum, num) => sum + num,
        0
      );
      numericValue > 9 && concatenatedResults.push(numericValue);
    }
    setResult(numericValue);
    setresultPath(concatenatedResults.join(" puis "));
    // Then show the results
    setShowResults(true);
  };
  const [borderColor, setBorderColor] = useState("#f3778f"); // Couleur de la bordure par défaut

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
  
    // Changer la couleur de la bordure en bleu même après le focus
    setBorderColor('#F3778F');
  
    // Vérifier si la valeur entrée est valide (par exemple, non vide)
    if (inputValue.trim() !== '') {
      setBorderColor('#F3778F'); // Changer la couleur de la bordure en rouge si la valeur est valide
    } else {
      setBorderColor('#F3778F'); // Garder la couleur de la bordure par défaut si la valeur est vide
    }
  
    // Mettre à jour la valeur saisie si nécessaire
    setInputValue(inputValue);
  };
  

  const handleInputFocus = () => {
    setBorderColor("#f3778f"); // Changer la couleur de la bordure en bleu lorsque le champ de texte obtient le focus
  };

  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#F3778F',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F3778F',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F3778F',
      },
      '&:hover fieldset': {
        borderColor: '#F3778F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F3778F',
      },
    },
  });

  const theme = useTheme();

  return (
   
    <React.Fragment>
      <CssBaseline />
      <Container
        fixed
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Box
          mt={10}
          sx={{
            borderRadius: "20px", // Border radius
            marginBottom: "80px",
            backgroundImage: `url(${backgroundCalcul})`, // Use template literals
            backgroundSize: "cover", // Adjust as needed
            p: "2vw",
            width: "50vw",
            height: "60vh",
            [theme.breakpoints.down("sm")]: {
              height: "80vh", // Adjusted height for small screens
              width: "80vw",
              p: "4vw",
            },
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center vertically
            alignItems: "center", // Center horizontally
          }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={3}>
            <Typography
              variant="h2"
              className="typo-responsive"
              sx={{
                color: "var(--taupe-fonc, #66534D)",
                textAlign: "center",
                fontFamily: "Majetto",
                fontSize: {
                  xs: '6vw', // Font size for extra small screens
                  sm: '3.5vw', // Font size for small screens
                  md: '3.5vw', // Font size for medium screens
                  lg: '3.5vw', // Font size for large screens
                  xl: '3.5vw' // Font size for extra large screens
              },
                fontStyle: "normal",
                fontWeight: 400,
                textTransform: "uppercase",
              }}>
              Calcul numérologique
            </Typography>
            <Typography
              variant="h4"
              style={{
                color: "var(--taupe-fonc, #66534D)",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
              }}>
              Chaque lettre sera convertie en chiffre pour calculer votre chiffre d'expression.
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            marginBottom={8}>
            <Grid item xs={12} md={6}>
              <CssTextField
                label="Enter votre texte"
                variant="outlined"
                color="secondary"
                onChange={handleInputChange}
                onFocus={handleInputFocus} // Gestionnaire d'événements pour mettre à jour la couleur de la bordure lorsque le champ de texte obtient le focus
                style={{
                  width: "100%",
                  maxWidth: "80vw",
                  height: "6vh",
                  flexShrink: 0,
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "21px",
                  backgroundColor: "#FFFFFF", // Fond blanc
                  borderColor: borderColor, // Couleur de la bordure définie par l'état
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component={Button}
                variant="contained"
                color="primary"
                onClick={() => handleCalculate(inputValue)}
                style={{
                  width: "100%",
                  maxWidth: "80vw",
                  height: "6vh",
                  flexShrink: 0,
                  borderRadius: "5px",
                  background: "var(--taupe-clair, #F3778F)",
                  boxShadow: "4px 24px 60px 0px rgba(109, 141, 173, 0.25)",
                  color: "var(--white, #FEFEFE)",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}>
                Calculer
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={3}>
            <Typography
              variant="h3"
              style={{
                color: "var(--taupe-fonc, #F3778F)",
                
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                textDecoration: "underline", // Ajouter le soulignement

              }}>
              Découvrir la signification de votre calcul numérologique
            </Typography>
          </Grid>
          {showResults && (
            <React.Fragment>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom={5}>
                <EllipseWithTextIcon calculatedResult={result} />
              </Grid>

              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom={8}>
                <Typography
                  variant="body1"
                  style={{
                    color: "var(--taupe-clair, #8D7268)",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "21px",
                    marginTop: "1rem", // Add margin at the top
                  }}>
                  Issu de {resultPath}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          {/* Additional content goes here */}
        </Box>
      </Container>
    </React.Fragment>
    
  );
};

// SvgIcon for Ellipse
const EllipseWithTextIcon = ({ calculatedResult }) => (
  <svg
    viewBox="0 0 100 100"
    width="90"
    height="90"
    fill="var(--taupe-clair, #8D7268)">
    <ellipse cx="50" cy="50" rx="50" ry="50" />
    <text
      x="50"
      y="50"
      textAnchor="middle"
      dy="0.3em"
      fill="var(--white, #FEFEFE)"
      fontFamily="Roboto"
      fontSize="65px"
      fontStyle="normal"
      fontWeight="400"
      lineHeight="65px"
      transform="uppercase">
      {calculatedResult}
    </text>
  </svg>
);

export default NumerologyCalculator;
