import React from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../../../Context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./PackvideoHome.css"; // Assurez-vous d'avoir le fichier CSS approprié
import PackvideoCard from "../Packvideo/Components/PackvideoCard/PackvideoCard"; // Assurez-vous que ce composant existe

const GradientCardBody = styled(CardBody)`
  border-radius: 20px;
`;
const CardContainer = styled.div`
  margin: 0 auto; /* Adjust the margin to control the space between cards */
`;

export default function PackvideoHome() {
  const navigate = useNavigate();
  const context = React.useContext(GlobalContext);
  const sliderSettings = {
    arrows: true,
    style: { maxWidth: "1100px", margin: "0 auto" },
    prevArrow: <div className="slick-prev"></div>,
    nextArrow: <div className="slick-next"></div>,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          style: {
            maxWidth: "100%",
            margin: "0 auto",
            maxHeight: "570px",
          },
        },
      },
    ],
  };

  if (!context.videoPacks) {
    return (
      <div>
        <section className="">
          <Slider {...sliderSettings}>
            {Array.from({ length: 3 }).map((_, index) => (
              <CardContainer key={index}>
                <Card style={{ width: "18rem" }} className="h-100">
                  <Skeleton variant="rectangular" height={270} />
                  <GradientCardBody>
                    <CardTitle tag="h5">
                      <Skeleton variant="text" width={100} />
                    </CardTitle>
                    <CardSubtitle tag="h6">
                      <Skeleton variant="text" width={80} />
                    </CardSubtitle>
                    <CardText style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="text" width={150} height={40} />
                    </CardText>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="text" width={100} height={30} />
                    </div>
                  </GradientCardBody>
                </Card>
              </CardContainer>
            ))}
          </Slider>
        </section>
      </div>
    );
  }

  return (
    <div className="" style={{ padding: '5% 10% 10% 10%' }}>
      <Slider {...sliderSettings}>
        {context.videoPacks.map((item) => (
          <PackvideoCard key={item.id_video_packs} packvideo={item} />
        ))}
      </Slider>
      <div className="div-more">
        <center>
          <button
            className="btn-more"
            onClick={() => navigate("/video-packs")}
            disabled={false}>
            Voir plus
          </button>
        </center>
      </div>
    </div>
  );
}
