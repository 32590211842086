import React, { useState } from "react";
import "./SearchBar.css";

function SearchBar(props) {
  const [input, setInput] = useState("");
  const handleSearch = () => {
    props.setSearchTerm(input);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Chercher un abonnement"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button onClick={handleSearch}>Chercher</button>
    </div>
  );
}

export default SearchBar;
