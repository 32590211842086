import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import styles from "./InfosModal.module.css";
import Calendar from "./Calendar/Calendar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function InfosModal(props) {
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [allDates, setAllDates] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null); // Initialize startDate state
  const [endDate, setEndDate] = React.useState(null); // Initialize endDate state
  const getAllDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      dates.push(currentDate.toISOString().slice(0, 19).replace("T", " "));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const fetchAvailabilities = () => {
    // Assuming there is only one availability per offer
    const availability = props.offre.offre?.offre_availability; // Safely access offre_availability
    if (availability) {
      const startDate = availability.date_debut;
      const endDate = availability.date_fin;
      
      console.log("Processing availability:", availability);
      console.log("Start Date:", startDate);
      console.log("End Date:", endDate);

      const availabilityDates = getAllDatesBetween(startDate, endDate);
      console.log("Generated availability dates:", availabilityDates);

      setAllDates(availabilityDates); // Set all dates to state
      setStartDate(startDate); // Update startDate state
      setEndDate(endDate); // Upda
    } else {
      console.warn("No availability found for this offer.");
    }
  };

  React.useEffect(() => {
    fetchAvailabilities();
  }, [props.offre]); // Dependency on props.offre to fetch availabilities

  const handleClose = () => {
    props.setOpenInfosModal(false);
  };

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };


  return (
    <React.Fragment>
      <Calendar
        isOpen={openCalendar}
        setOpenCalendar={setOpenCalendar}
        offre={props.offre}
        availableDates={allDates}
        startDate={startDate}  // Pass your start date here
        endDate={endDate}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Réserver cette offre :{" "}
          <b style={{ color: "#F3778F" }}>{props.offre.titre_offre} </b>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className={styles["content-text"]}>
            <Typography
              guerBottom
              variant="h7"
              fontWeight="bold"
              marginRight="8px">
              Titre:
            </Typography>
            <DialogContentText>{props.offre.titre_offre}</DialogContentText>
          </div>

          <Typography gutterBottom variant="h7" fontWeight="bold">
            Description:
          </Typography>
          <DialogContentText>{props.offre.description_offre}</DialogContentText>

          <div className={styles["content-text"]}>
            <Typography gutterBottom variant="h7" fontWeight="bold">
              Durée:
            </Typography>
            <DialogContentText>
              {props.offre.duree_offre} minutes
            </DialogContentText>
          </div>

          <div className={styles["content-text"]}>
            <Typography gutterBottom variant="h7" fontWeight="bold">
              Type:
            </Typography>
            <DialogContentText>{props.offre.type_offre}</DialogContentText>
          </div>
          <div className={styles["content-text"]}>
            <Typography gutterBottom variant="h7" fontWeight="bold">
              Prix:
            </Typography>
            <DialogContentText>{props.offre.prix_offre}€</DialogContentText>
          </div>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <p style={{ flex: 1, marginRight: "20px" }}>
            {props.offre.is_accessible_participant === 1 ? (
              <span style={{ fontStyle: "italic", color: "green" }}>
                * Session autorisée pour les abonnés ayant des jetons. Sinon,
                veuillez procéder au paiement pour participer.
              </span>
            ) : (
              <span style={{ fontStyle: "italic", color: "red" }}>
                * Les jetons abonnés ne sont pas acceptés. Le paiement direct
                est requis pour réserver la session.
              </span>
            )}
          </p>
          <Button
            autoFocus
            onClick={handleOpenCalendar}
            variant="outlined"
            color="secondary"
            style={{
              color: "#F3778F",
              borderColor: "#F3778F",
              borderRadius: "10px",
            }}>
            Réserver
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
