import React from "react";
import Box from "@mui/joy/Box";
import Slider, { sliderClasses } from "@mui/joy/Slider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./PriceSlider.css";

function valueText(value) {
  return `${value}Є`;
}

export default function PriceSlider(props) {
  //Triggered continuously as the user drags the slider thumb
  const [tempSliderPriceValues, setTempSliderPriceValues] = React.useState(
    props.sliderPriceValues
  );
  const [subsCount, setSubsCount] = React.useState(
    props.subs && props.subs.length
  );

  const handleChange = (event, newValue) => {
    setTempSliderPriceValues(newValue);
    updateFilteredProducts(newValue);
  };

  const handleOKClick = () => {
    props.setSliderPriceValues(tempSliderPriceValues);
  };

  const updateFilteredProducts = (newValues) => {

    const filteredProducts = props.subs.filter(
      (sub) => sub.price_subs >= newValues[0] && sub.price_subs <= newValues[1]
    );
    setSubsCount(filteredProducts.length);
  };

  /* //after release slider thumb
  const handleChangeCommitted = (event, newValue) => {}; */

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "1.5vw",
      }}
    >
      <Slider
        track={false}
        value={tempSliderPriceValues}
        min={10}
        max={150}
        getAriaLabel={() => "Amount"}
        getAriaValueText={valueText}
        valueLabelFormat={valueText}
        valueLabelDisplay="on"
        onChange={handleChange}
        /* onChangeCommitted={handleChangeCommitted} */

        sx={{
          width: "100%",
          ".MuiSlider-markLabel, .MuiSlider-valueLabel": {
            color: "#66534d",
          },
          ".MuiSlider-valueLabel": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          ".css-hayzob-JoySlider-thumb::before, .MuiSlider-thumb": {
            backgroundColor: "#cc8c97",
            border: "2px solid #cc8c97",
          },
          ".MuiSlider-valueLabel::before": {
            display: "none",
          },
          // Need both of the selectors to make it work on the server-side and client-side
          [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
            [`&.${sliderClasses.markLabel}`]: {
              transform: "none",
            },
            [`& .${sliderClasses.valueLabel}`]: {
              left: "calc(var(--Slider-thumbSize) / 2)",
              borderBottomLeftRadius: 0,
              "&::before": {
                left: 0,
                transform: "translateY(100%)",
                borderLeftColor: "currentColor",
              },
            },
          },
          [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
            [`&.${sliderClasses.markLabel}`]: {
              transform: "translateX(-100%)",
            },
            [`& .${sliderClasses.valueLabel}`]: {
              right: "calc(var(--Slider-thumbSize) / 2)",
              borderBottomRightRadius: 0,
              "&::before": {
                left: "initial",
                right: 0,
                transform: "translateY(100%)",
                borderRightColor: "currentColor",
              },
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1vw",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#66534d",
            fontSize: "0.75rem",
            fontFamily: "Roboto",
            alignSelf: "flex-start",
          }}
        >
          {`${subsCount} produits`}
        </Typography>
        <Button
          className="buttonStyle"
          variant="contained"
          color="primary"
          style={{ alignSelf: "flex-end" }}
          onClick={handleOKClick}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
}
