// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Packvideos_subsCards__WV3tn {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    
}
.Packvideos_paginationContainer__Bduqw {
    display: flex;
    justify-content: center;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/PackvideoPage/Packvideo/Packvideos.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,SAAS;IACT,uBAAuB;;AAE3B;AACA;IACI,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".subsCards {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n    gap: 10px;\n    align-items: flex-start;\n    \n}\n.paginationContainer {\n    display: flex;\n    justify-content: center;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subsCards": `Packvideos_subsCards__WV3tn`,
	"paginationContainer": `Packvideos_paginationContainer__Bduqw`
};
export default ___CSS_LOADER_EXPORT___;
