import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact-page/Contact";
import Footer from "./Pages/Footer/Footer";
import Profile from "./Pages/Client-profile/ClientProfile";
import IndexNavbar from "./Pages/IndexNavbar/IndexNavbar";
import SignUpForm from "./Pages/SignUp/SignUpForm";
import LoginForm from "./Pages/Login/LoginForm";
import AppContext from "./Context/AppContext";
import IntervenantsPage from "./Pages/IntervenantsPage/IntervenantsPage";
import ProfileIntervenant from "./Pages/IntervenantsPage/Intervenants/ProfileIntervenant/ProfileIntervenant";
import SubscriptionsPage from "./Pages/Home/Pages/SubscriptionsPage/SubscriptionsPage";
import SubscriptionInfoDetails from "./Pages/Home/Pages/SubscriptionsPage/Subscriptions/Components/SubscriptionCard/SubscriptionInfoDetails";
import Mentions_legales from "./Pages/Info_legales/mention_legale/mentions_legales";
import APropos from "./Pages/A-propos/APropos";
import ScrollToTop from "./Utils/ScrollToTop";
import MessageDisplay from "./components/MessageDisplay/MessageDisplay";
import PackvideoDetails from "./Pages/Home/Pages/PackvideoPage/Packvideo/Components/PackvideoCard/PackvideoDetails";
import PackvideoPage from "./Pages/Home/Pages/PackvideoPage/PackvideoPage";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
      <ScrollToTop />
        <AppContext>
          <IndexNavbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/intervenants" element={<IntervenantsPage />} />
            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/video-packs" element={<PackvideoPage />} />

            
            <Route
              path="/intervenants/:fullName/?"
              element={<ProfileIntervenant />}
            />
            <Route
              path="/public/subs/g/:id_subs"
              element={<SubscriptionInfoDetails />}
            />
            <Route
              path="/public/pack/g/:id"
              element={<PackvideoDetails />}
            />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/mentions_legales" element={<Mentions_legales />} />
            <Route path="/A-Propos" element={<APropos />} />
            <Route path="/message" element={<MessageDisplay />} /> {/* Nouvelle route pour afficher le message */}
          </Routes>
          <Footer />
        </AppContext>
      </BrowserRouter>
    </div>
  );
}
export default App;
