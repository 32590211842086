// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UserCard.module.css */

.UserCard_user-card__IBao9 {
    position: relative;
    width: 280px;
    min-height: 47vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .UserCard_availability-icon__ya0dE {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    padding: 10px;
    z-index: 1;
  }
  
  .UserCard_user-photo__TP59x {
    width: 100%;
  }
  
  .UserCard_rating-section__Fv2XP {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .UserCard_satisfaction-label__XPZWS {
    color: #66534D;
    font-size: 16px;
  }
  
  .UserCard_user-name__0OTka {
    color: #66534D;
    text-align: center;
    font-size: 20px;
  }
  
  .UserCard_user-speciality__\\+oSa7 {
    color: #66534D;
    text-align: center;
    font-size: 16px;
  }
  
  .UserCard_en-savoir-plus__4pZ91 {
    text-align: center;
  }

  .UserCard_cardBody__9Wv1t {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Intervenants/Components/UserCard/UserCard.module.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,yCAAyC;IACzC,mBAAmB;IACnB,oBAAoB;EACtB;EACA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,UAAU;IACV,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;EACrB","sourcesContent":["/* UserCard.module.css */\n\n.user-card {\n    position: relative;\n    width: 280px;\n    min-height: 47vh;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    margin-bottom: 20px;\n    padding-bottom: 20px;\n  }\n  .availability-icon {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 40%;\n    padding: 10px;\n    z-index: 1;\n  }\n  \n  .user-photo {\n    width: 100%;\n  }\n  \n  .rating-section {\n    margin-top: 10px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .satisfaction-label {\n    color: #66534D;\n    font-size: 16px;\n  }\n  \n  .user-name {\n    color: #66534D;\n    text-align: center;\n    font-size: 20px;\n  }\n  \n  .user-speciality {\n    color: #66534D;\n    text-align: center;\n    font-size: 16px;\n  }\n  \n  .en-savoir-plus {\n    text-align: center;\n  }\n\n  .cardBody {\n    padding: 0 !important;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card": `UserCard_user-card__IBao9`,
	"availability-icon": `UserCard_availability-icon__ya0dE`,
	"user-photo": `UserCard_user-photo__TP59x`,
	"rating-section": `UserCard_rating-section__Fv2XP`,
	"satisfaction-label": `UserCard_satisfaction-label__XPZWS`,
	"user-name": `UserCard_user-name__0OTka`,
	"user-speciality": `UserCard_user-speciality__+oSa7`,
	"en-savoir-plus": `UserCard_en-savoir-plus__4pZ91`,
	"cardBody": `UserCard_cardBody__9Wv1t`
};
export default ___CSS_LOADER_EXPORT___;
